import React from 'react'

const DropdownIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
                <path d="M7.99299 9.44455C7.81031 9.44455 7.63544 9.37083 7.50767 9.23969L0.192636 1.72437C-0.0686631 1.45563 -0.0635795 1.02553 0.204498 0.76325C0.472237 0.501312 0.901636 0.506748 1.16294 0.775141L7.99265 7.79207L14.8369 0.760532C15.0986 0.4918 15.528 0.487044 15.7954 0.748641C16.0634 1.01058 16.0689 1.44103 15.8072 1.70976L8.47831 9.23969C8.35054 9.37083 8.17566 9.44455 7.99299 9.44455Z" fill="#4E585E" />
            </svg>
        </>
    )
}

export default DropdownIcon