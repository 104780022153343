import React from 'react'

const CloseIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="#000000">
                <path d="M11.2786 0.000196904C11.0924 0.00459189 10.9155 0.0825257 10.7866 0.216963L5.99984 5.00372L1.21309 0.216963C1.14734 0.149377 1.0687 0.0956728 0.981822 0.05903C0.894943 0.0223871 0.801594 0.00355111 0.707303 0.00363764C0.567181 0.00381119 0.430292 0.0457518 0.314122 0.124102C0.197951 0.202452 0.107775 0.313654 0.0551112 0.443503C0.00244774 0.573353 -0.0103117 0.715953 0.0184629 0.85309C0.0472375 0.990226 0.11624 1.11567 0.216655 1.2134L5.0034 6.00015L0.216655 10.7869C0.149026 10.8518 0.0950322 10.9296 0.0578364 11.0157C0.0206407 11.1017 0.000990421 11.1943 3.64843e-05 11.2881C-0.000917452 11.3818 0.016844 11.4748 0.0522809 11.5616C0.0877177 11.6484 0.140118 11.7273 0.206411 11.7936C0.272705 11.8599 0.351561 11.9123 0.438359 11.9477C0.525158 11.9832 0.618155 12.0009 0.711903 12C0.805652 11.999 0.898268 11.9794 0.984328 11.9422C1.07039 11.905 1.14816 11.851 1.21309 11.7833L5.99984 6.99659L10.7866 11.7833C10.8515 11.851 10.9293 11.905 11.0154 11.9422C11.1014 11.9794 11.194 11.999 11.2878 12C11.3815 12.0009 11.4745 11.9832 11.5613 11.9477C11.6481 11.9123 11.727 11.8599 11.7933 11.7936C11.8596 11.7273 11.912 11.6484 11.9474 11.5616C11.9828 11.4748 12.0006 11.3818 11.9996 11.2881C11.9987 11.1943 11.979 11.1017 11.9418 11.0157C11.9046 10.9296 11.8507 10.8518 11.783 10.7869L6.99628 6.00015L11.783 1.2134C11.8856 1.11508 11.956 0.988059 11.9851 0.849006C12.0142 0.709953 12.0005 0.565356 11.946 0.43418C11.8915 0.303004 11.7985 0.191373 11.6795 0.113928C11.5604 0.036483 11.4206 -0.00316041 11.2786 0.000196904Z" fill="black" />
            </svg>
        </>
    )
}

export default CloseIcon