import React from 'react'

const TreeAge = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="17" viewBox="0 0 14 17" fill="none">
                <path d="M6.99641 0C5.46176 0 4.21331 1.0791 3.83235 2.5H3.76399C2.55657 2.5 1.4107 3.31493 1.19889 4.57682C1.07421 5.31838 1.30375 6.01911 1.73437 6.55273C0.750216 7.2895 0.129876 8.49559 0.394851 9.83236H0.396479V9.83398C0.716096 11.4411 2.19081 12.5 3.77213 12.5H6.16308V15H3.66308V16.6667H10.3297V15H7.82975V12.5H10.2191C11.7999 12.5 13.2771 11.441 13.5963 9.83398V9.83236C13.8613 8.49573 13.2412 7.29081 12.2568 6.55436C12.6884 6.02054 12.9188 5.31918 12.7939 4.57682C12.5821 3.31493 11.4363 2.5 10.2288 2.5H10.1605C9.77952 1.0791 8.53107 0 6.99641 0ZM6.99641 1.66667C7.91687 1.66667 8.66308 2.41288 8.66308 3.33333V4.16667H9.49641H10.2288C10.6848 4.16667 11.0869 4.47708 11.1501 4.85352C11.2424 5.40259 10.8387 5.83333 10.3297 5.83333V7.5C11.3608 7.5 12.1804 8.41067 11.9622 9.50846C11.8109 10.2722 11.0561 10.8333 10.2191 10.8333H3.77213C2.93512 10.8333 2.18265 10.273 2.03059 9.50846C1.81297 8.41057 2.63204 7.5 3.66308 7.5V5.83333C3.15409 5.83333 2.75045 5.40259 2.84277 4.85352C2.90595 4.47708 3.30808 4.16667 3.76399 4.16667H4.49641H5.32975V3.33333C5.32975 2.41288 6.07596 1.66667 6.99641 1.66667Z" fill="#AEBE02" />
            </svg>
        </>
    )
}

export default TreeAge