import React from "react";

export const SearchIcon = (props) => {
    const width = props?.width ? props?.width : 16;
    const height = props?.height ? props?.height : 16;
    const color = props?.color ? props?.color : '#706b6b';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 16 16"
            {...props}
        >
            <path id="icons8-search" d="M13.16,6a7.16,7.16,0,1,0,4.306,12.865l2.847,2.846a.988.988,0,1,0,1.4-1.4l-2.846-2.847A7.144,7.144,0,0,0,13.16,6Zm0,1.975a5.186,5.186,0,0,1,3.777,8.739.988.988,0,0,0-.221.221,5.186,5.186,0,1,1-3.556-8.96Z" transform="translate(-6 -6)"
                fill={color}
            />
        </svg>
    );
};