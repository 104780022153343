import { Typography, Button } from "@mui/material";

export default function ContributionCertificateScreen(props) {

    const { HandleBackToBTH } = props;

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: "#fff" }}>
            <div style={{
                width: "360px",
                height: "calc(100vh - 64px)",
                overflowY: 'auto',
                background: "rgba(11, 148, 68, 0.08)"
            }}>

                <Typography sx={{
                    color: "#02111A",
                    textAlign: "center",
                    fontFamily: "crayond_medium",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "-0.18px",
                    padding: '32px',
                    marginBottom: "-26px"
                }}>{"Thanks for your contribution!"}</Typography>
                <Typography sx={{
                    color: "#6D6D6D",
                    textAlign: "center",
                    fontFamily: "crayond_regular",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20px",
                    padding: "16px",
                    paddingTop: "-16px",
                    paddingRight: "32px",
                    paddingLeft: "32px"
                }}>{"Your effort in planting trees is truly invaluable."}</Typography>

                <div style={{
                    width: "320px",
                    height: "480px",
                    flexShrink: 0,
                    borderRadius: "12px",
                    background: "rgba(11, 148, 68, 0.08)",
                    margin: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}>
                    <img src={"/Certificate.png"} />
                    <Button
                        sx={{
                            borderRadius: "8px",
                            border: "1px solid #17970E",
                            background: "#17970E",
                            width: "296px",
                            height: "40px",
                            marginTop: '18px',
                            // padding: '20px',
                            boxShadow: 'none',
                            ':hover': {
                                background: "#17970E",
                            }
                        }}
                        variant={"contained"}
                        color={"primary"}
                    // onClick={onLogin}
                    >
                        <Typography sx={{
                            fontFamily: "crayond_regular",
                            color: "#FFF",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "20px",
                            textTransform: 'none'
                        }}>{"Download Certificate"}</Typography>
                    </Button>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        sx={{
                            borderRadius: "8px",
                            border: "1px solid rgba(0, 0, 0, 0.08)",
                            background: "#fff",
                            width: "320px",
                            height: "40px",
                            marginTop: '18px',
                            marginBottom: '18px',
                            padding: '20px',
                            boxShadow: 'none',
                            ':hover': {
                                background: "#fff",
                            }
                        }}
                        variant={"contained"}
                        color={"primary"}
                        onClick={HandleBackToBTH}
                    >
                        <Typography sx={{

                            fontFamily: "crayond_regular",
                            color: "#000",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "20px",
                            textTransform: 'none'
                        }}>{"Back to Tree Hugger"}</Typography>
                    </Button>
                </div>
            </div>
        </div>
    );
}