import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    root: {
        overflowX: "auto",
        display: "flex",
        flexFlow: "nowrap",
        position: "absolute", 
        zIndex: 100, 
        left: "32%",
        [theme.breakpoints.down("sm")]: {
            left: "0%",
            width: "100%",
        },
        [theme.breakpoints.between("600", "769")]: {
            left: "calc(100vw - 90vw)",
            width: "100%",
        },
        [theme.breakpoints.between("769", "1025")]: {
            left: "20%",
            width: "100%",
        },
    },
    cardlist:{
        // display: "flex", 
        padding: "12px 12px",
        borderRadius: "12px",
        [theme.breakpoints.down("md")]: {
           flexWrap: "wrap",
        },
        [theme.breakpoints.up("425")]: {
            padding: "12px 12px"
        }
    },
    textView: {
        width: "130px",
        marginBottom: "4px",
        marginRight: "6px",
        color: "gray",
        fontSize: "12px",
        // [theme.breakpoints.down("sm")]: {
        //     fontSize: 12,
        // },
    },
    counts: {
        // color: "#67b845", 
        fontWeight: 600, 
        // paddingLeft: "8px"
    },
    divider1: {
        height: "20px", alignSelf: "center", margin: "0px",marginRight:6
    },
    divider2: {
        height: "20px", alignSelf: "center", margin: "0px",marginRight:6,
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
        [theme.breakpoints.up("425")]: {
            display: "block",
        }
    },
    divider3: {
        height: "20px", alignSelf: "center", margin: "0px",marginRight:6,
        [theme.breakpoints.up("425")]: {
            display: "none",
        },
        [theme.breakpoints.up("535")]: {
            display: "block",
        }
    },
  }));

export default function Stas(props) {
    const classes = useStyles();

    const getAvg = (data, type) => {
        if(data?.length > 0){
            let grades = null;
            if(type === "Oxygen"){
                grades = data.map(val => Number(val.forestOxygen))
            }else if(type === "Carbon"){
                grades = data.map(val => Number(val.forestCarbonoffset))
            }else{
                grades = "0"
            }
            const total = grades?.reduce((acc, c) => acc + c, 0);
            const avg = total / 1000;
            return Math.round(avg);
        }
    }

    const getTotel = (data, type) => {
        if(data?.length > 0){
            let totalArry = null;
            let sum = null;
            if(type === "Species"){
                let set = [];
                totalArry = data.map(val => val.forestTreesByForestId.nodes.map(v=>v?.masterPlantspecyByMasterPlantSpeciesId?.speciesName)).map(a => a.map(v => set.push(v)));
                // masterPlantspecyByMasterPlantSpeciesId
                // speciesName
                set = [...new Set(set)]
                sum = set.length;
            }else if(type === "Trees"){
                totalArry = data.map(val => Number(val?.forestTreesByForestId?.totalCount))
                sum = totalArry.reduce((partialSum, a) => partialSum + a, 0);
            }else{
                sum = "0";
            }
            return sum;
        }
    }

    const getTreeTotal = (data, type) => {
        if(data?.length > 0){
            let totalArry = null;
            let sum = null;
            if(type === "Species"){
                totalArry = data.map(val => Number(val?.totalSpeciesPlanted))
                sum = totalArry.reduce((partialSum, a) => partialSum + a, 0);
            }else if(type === "Trees"){
                totalArry = data.map(val => Number(val?.totalTrees))
                sum = totalArry.reduce((partialSum, a) => partialSum + a, 0);
            }else{
                sum = "0";
            }
            return sum;
        }
    }

  return (
    <Box
        sx={{
            display: 'flex',
            flexWrap: 'wrap',
            '& > :not(style)': {
            m: 1
            },
        }}
        className={classes.root}
        >
        {/* <Paper elevation={3} className={classes.cardlist} >
            <Typography variant={"subtitle2"} className={classes.textView} >
                {"Oxygen Generated"}<Typography variant={"subtitle2"} component={"span"} className={classes.counts} >
                {getAvg(props.data, "Oxygen")}{" kgs"}</Typography>
            </Typography>
                <Divider orientation="vertical" flexItem className={classes.divider1} />
            <Typography variant={"subtitle2"} className={classes.textView} >
                {"Carbon Offset"}<Typography variant={"subtitle2"} component={"span"} className={classes.counts} >
                {getAvg(props.data, "Carbon")}{" kgs "}</Typography>
            </Typography>
                <Divider orientation="vertical" flexItem className={classes.divider2} />
            <Typography variant={"subtitle2"} className={classes.textView} >
                {"Trees Planted"}<Typography variant={"subtitle2"} component={"span"} className={classes.counts} >
                {getTreeTotal(props.data, "Trees")}</Typography>
            </Typography>
            <Divider orientation="vertical" flexItem className={classes.divider3} />
            <Typography variant={"subtitle2"} className={classes.textView} >
                {"Species Planted"}<Typography variant={"subtitle2"} component={"span"} className={classes.counts} >
                {getTreeTotal(props.data, "Species")}</Typography>
            </Typography>
        </Paper> */}

        <Paper elevation={3} className={classes.cardlist} >
            <Typography variant={"subtitle2"} className={classes.textView} >{"Oxygen Generated"}</Typography>
            <Typography variant={"subtitle2"} className={classes.counts} >{getAvg(props.data, "Oxygen")?.toLocaleString()}{" kt"}</Typography>
        </Paper>

        <Paper elevation={3} className={classes.cardlist} >
            <Typography variant={"subtitle2"} className={classes.textView} >{"Carbon Offset"}</Typography>
            <Typography variant={"subtitle2"} className={classes.counts} >{getAvg(props.data, "Carbon")?.toLocaleString()}{" kt "}</Typography>
        </Paper>

        <Paper elevation={3} className={classes.cardlist} >
            <Typography variant={"subtitle2"} className={classes.textView} >{"Trees Planted"}</Typography>
            <Typography variant={"subtitle2"} className={classes.counts} >{getTreeTotal(props.data, "Trees")?.toLocaleString()}</Typography>
        </Paper>

        <Paper elevation={3} className={classes.cardlist} >
            <Typography variant={"subtitle2"} className={classes.textView} >{"Species Planted"}</Typography>
            <Typography variant={"subtitle2"} className={classes.counts} >{getTreeTotal(props.data, "Species")?.toLocaleString()}</Typography>
        </Paper>

    </Box>
  );
}
