import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { RenderMap } from "../../components/map/mapRenderOld";
import mapStyles from '../../components/map/mapStyles.json';

export const AddTreeForm = props => {
  
    const [infoWindow, setInfoWindow] = useState();
    const [map, setMap] = React.useState();
    const [location, setLocation] = useState(null);
    const [forestsAndTreesList, setForestsAndTreesList] = useState({
      data: []
    })
    const [selectedNode, setSelectedNode] = useState({
      marker: null,
      circle: null
    })

    const checkLatLong = [
      // {
      //     forestGeoLat: 12.950109777557856,
      //     forestGeoLong: 80.25670814249283
      // },
      // {
      //     forestGeoLat: 12.950127241313131,
      //     forestGeoLong: 80.25672168371791
      // }
  ]

    useEffect(()=> {

        // setForestsAndTreesList({
        //   ...forestsAndTreesList,
        //   data: checkLatLong
        // })
        if (navigator.geolocation) {
            navigator.permissions.query({name:'geolocation'}).then(permissionStatus => {
              if (permissionStatus.state === 'denied') {
                // alert('Please allow location access.');
                window.location.href = "app-settings:location";
              }
            });
          } else {
            alert('Geolocation is not supported in your browser.');
          }

    },[])

    useEffect(()=>{
      if(location){
        const dataLatLong = {
          forestGeoLat: location.latitude, 
          forestGeoLong: location.longitude
        }
        setForestsAndTreesList({
          ...forestsAndTreesList,
          data: [...forestsAndTreesList?.data, dataLatLong] 
        })
      }
    },[location])


    const handleClick = () => {
        if (navigator.geolocation) {
          const options = {
            enableHighAccuracy: true, // Set this to true for high accuracy
          };
    
          // Check if the browser has asked for permission before
          if ("permissions" in navigator) {
            navigator.permissions.query({ name: 'geolocation' })
              .then((result) => {
                if (result.state === 'granted' || result.state === 'prompt') {
                  // Permission is granted or prompt the user for permission
                  navigator.geolocation.getCurrentPosition(
                    (position) => {
                      const { latitude, longitude } = position.coords;
                      setLocation({ latitude, longitude });
                    },
                    (error) => {
                      if (error.code === 1) {
                        // Permission denied
                        console.error('Permission denied by user');
                      } else {
                        // Other errors
                        console.error('Error getting location:', error.message);
                      }
                    },
                    options
                  );
                } else {
                  console.error('Permission denied by user');
                }
              })
              .catch((error) => {
                console.error('Error checking geolocation permission:', error);
              });
          } else {
            // For older browsers that do not support the Permissions API
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                setLocation({ latitude, longitude });
              },
              (error) => {
                if (error.code === 1) {
                  // Permission denied
                  console.error('Permission denied by user');
                } else {
                  // Other errors
                  console.error('Error getting location:', error.message);
                }
              },
              options
            );
          }
        } else {
          alert('Geolocation is not supported by your browser');
        }
      };

      const onClickMap = (e) => {
        let findPopup = document?.getElementsByClassName('gm-style-iw-t');
        if (findPopup?.length) {
            //google info window popup
            findPopup[0].style.display = "none"
            if (e.placeId) {
                findPopup[0].style.display = "block"
            }
            // //custom info window popup
            closeInfoWindow()
        }
    }

    const closeInfoWindow = () => {
        if (infoWindow?.getMap()) {
            infoWindow.close();
        }
    }

    const center = () => {
      return {
        lat: forestsAndTreesList?.data?.[0]?.forestGeoLat,
        lng: forestsAndTreesList?.data?.[0]?.forestGeoLong
      }
    }

    const renderStatus = (status) => {
      if (status === "FAILURE") return <p component="div">Something went wrong!</p>;
      return <div component="div" variant="rectangular">
          <img src={""} alt={"logo"} />
      </div>;
  };

    return (
        <div
            style={{
                width: '100%',
                height:'100%'
                // height: 'calc(100vh - 64px)',
            }}
        >
          <div style={{display: "flex"}} >
          <Button onClick={handleClick} style={{ margin: "20px" }} variant={"outlined"} >{"Add Marker"}</Button>
          {location && (
                <div style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
                <p>{"Latitude - Longitude:  "}</p><p style={{paddingLeft: 12}} >{location.latitude}{" , "}{location.longitude}</p>
                <p style={{paddingLeft: 12}} >{"Tree: "}{forestsAndTreesList?.data?.length}</p>
                </div>
            )}
          </div>

            


            {(forestsAndTreesList?.data?.length > 0) &&
                <RenderMap 
                    center={center()}
                    initialZoom={22} 
                    minZoom={3} 
                    // maxZoom={allTrees?.maxZoom}
                    onIdle={()=>null}
                    onClickMap={onClickMap}
                    infoWindow={infoWindow} 
                    mapStyles={mapStyles} 
                    map={map}
                    setMap={setMap}
                    allTrees={null}
                    forestsAndTreesList={forestsAndTreesList}
                    renderStatus={renderStatus}
                    selectedNode={selectedNode}
                    setInfoWindow={setInfoWindow}
                    circleClick={()=>null}
                    markerIcon={""}
                    markerClick={()=>null}
                    areaClick={()=>null}
                    setAllTrees={()=>null}
                />
            }
            
        </div>
    )
}