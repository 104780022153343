import { useState, useEffect } from 'react';
// import ReactDOM from 'react-dom';

export const Marker = (props) => {

    const [marker, setMarker] = useState();
    // const [infoWindow, setInfoWindow] = useState();

    const {
        infoWindow = null,
        setInfoWindow = null,
        url = ''
    } = props;


    useEffect(() => {
        if (!marker) {
            setMarker(new window.google.maps.Marker({
                animation: window.google.maps.Animation.DROP
            }));
        }
        if (!infoWindow) {
            setInfoWindow(new window.google.maps.InfoWindow({
                content: '',
            }))
        }

        // remove marker from map on unmount
        return () => {
            if (marker) {
                marker.setMap(null);
            }
            if (infoWindow) {
                infoWindow.setMap(null);
            }
        };
        // eslint-disable-next-line
    }, [marker, infoWindow]);

    useEffect(() => {
        let iconAddped = {}
        if(url){
            iconAddped = {
                icon: {
                    url: url,
                    scaledSize: new window.google.maps.Size(120, 120)
                }
            }
        }
        if (marker) {
            if (!marker.getMap()) {
                marker.setMap(props?.map);
            }
            marker.setOptions({
                ...props,
                ...iconAddped
            });
        }
    }, [marker, props])

    useEffect(() => {

        if (marker) {
            marker.addListener("click", () => {
                props?.markerClick && props?.markerClick(marker)
            });
        }
        // eslint-disable-next-line
    }, [marker])

    return null;
}