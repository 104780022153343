import React from 'react'

const CarbanIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 1.3H2C1.6134 1.3 1.3 1.6134 1.3 2V13C1.3 13.3866 1.6134 13.7 2 13.7H13C13.3866 13.7 13.7 13.3866 13.7 13V2C13.7 1.6134 13.3866 1.3 13 1.3ZM2 0C0.895431 0 0 0.89543 0 2V13C0 14.1046 0.89543 15 2 15H13C14.1046 15 15 14.1046 15 13V2C15 0.895431 14.1046 0 13 0H2Z" fill="#A502BE" />
                <path d="M7.77079 4.01362C6.98706 4.01362 6.36969 4.28247 5.91867 4.82016C5.47505 5.35059 5.25323 6.08084 5.25323 7.0109C5.25323 7.9337 5.46026 8.66031 5.87431 9.19074C6.28836 9.72116 6.91682 9.98638 7.7597 9.98638C8.09982 9.98638 8.42514 9.95731 8.73568 9.89918C9.05361 9.83379 9.37523 9.75023 9.70055 9.6485V10.6621C9.39002 10.7784 9.07209 10.8619 8.74677 10.9128C8.42144 10.9709 8.04436 11 7.61553 11C6.80222 11 6.12569 10.8365 5.58595 10.5095C5.0536 10.1753 4.65434 9.71026 4.38817 9.11444C4.12939 8.51135 4 7.80654 4 7C4 6.21526 4.14418 5.52498 4.43253 4.92915C4.72828 4.32607 5.15712 3.85377 5.71904 3.51226C6.28096 3.17075 6.96858 3 7.78189 3C8.18115 3 8.56932 3.0436 8.9464 3.13079C9.33087 3.21072 9.68207 3.32698 10 3.47956L9.55638 4.46049C9.2976 4.34423 9.02033 4.24251 8.72458 4.15531C8.42884 4.06085 8.11091 4.01362 7.77079 4.01362Z" fill="#A502BE" />
            </svg>
        </>
    )
}

export default CarbanIcon