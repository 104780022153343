import React, { useCallback, useContext, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { Status, } from "@googlemaps/react-wrapper";
import { InfoDetails } from './infoDetails';
import mapStyles from './mapStyles.json';
import Skeleton from '@mui/material/Skeleton';
import styled from '@mui/material/styles/styled';
import { DialogContext } from '../../contexts';
import { HoverDetails } from './hoverDetails';
// import { config } from '../../config';
import { useApolloClient } from "@apollo/client";
import { GET_TREES_BY_NODEID, GET_ALL_FORESTS_AND_TREES, GET_FOREST_DETAILS_BY_ID, GET_ALL_TREES } from '../../graphql/queries';
// import { loadScript } from '../../utils';
// import { Overlay } from './overlay';
import Stas from "./stasHeader";
import Logo from '../../assets/logo.png';
import { common } from '@mui/material/colors';
import GoogleMapReact from 'google-map-react'
// import MarkerClusterer from '@google/markerclusterer'
import GoogleMapContainer from "./mapRender";
import { RenderMap } from "./mapRenderOld";
import { useParams, useLocation } from 'react-router-dom';
import { FooterButton } from "../footer";
import TreeInfo from './treeInfo';
import { maxHeight, maxWidth } from '@mui/system';

const CustomSkeleton = styled(Skeleton)({
    minHeight: '100%',
    minWidth: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'unset',
    backgroundColor: "#fff",
    '& img': {
        visibility: "visible",
        maxWidth: "100%",
        height: "auto",
        padding: 10
    }
})

let initialZoom = 15.5, minZoom = 3;
// let center = {
//     "lat": 13.049799526425561,
//     "lng": 80.09315294503136
// }

let dialogStyle = [{
    '& .MuiDialogContent-root': {
        padding: 0,
        overflowX: 'hidden'
    }
}]


export const CustomMap = (props) => {
    let path = useParams();
    let currentPath = useLocation();
    const {
        forestDetails = []
    } = props;
    const client = useApolloClient();

    const dialog = useContext(DialogContext);

    // const [pagination, setPagination] = useState({
    //     offset: 0,
    //     limit: 1
    // });

    const [forestsAndTreesList, setForestsAndTreesList] = useState({
        data: [],
        totalCount: 0,
        treesList: []
    });
    const [allTrees, setAllTrees] = useState({
        list: [],
        totalCount: null,
        offset: 1,
        maxZoom: 18
    })


    // const [loading, setLoading] = useState(null);

    const [map, setMap] = React.useState();

    const [infoWindow, setInfoWindow] = useState();

    const [timelineOrder, setTimelineOrder] = useState({ label: "", value: '' });

    const [selectedNode, setSelectedNode] = useState({
        marker: null,
        circle: null
    })

    const [selectedForest, setSelectedForest] = useState();

    const getAllTrees = async (id) => {
        // GET_ALL_TREES
        await client.query({
            query: GET_ALL_TREES,
            variables: {
                offset: allTrees.offset
            }
        }).then(res => {
            const allTreesData = res?.data?.allForestTrees?.nodes;
            const totalCountData = res?.data?.allForestTrees?.totalCount;
            setAllTrees({
                list: [...allTreesData],
                totalCount: totalCountData,
                offset: allTrees.offset + 1
            });
        }).catch(err => {
            console.log(err)
        })
    }

    const backToforest = () => {
        if (selectedForest) {
            map.setCenter({
                lat: Number(selectedForest?.forestGeoLat),
                lng: Number(selectedForest?.forestGeoLong),
            });
            map.setZoom(initialZoom);
            setAllTrees({
                list: [],
                totalCount: null,
                offset: 1
            })
        }
    }

    const handleLocationTree = () => {
        // closeDialog();
    }

    const handleOrderTimeline = (e, val) => {
        setTimelineOrder(val)
    }

    const closeDialog = () => {
        dialog.setDialog({
            open: false
        })
    }

    const backToCluster = () => {
        if (allTrees?.clusterZoom && selectedForest) {
            areaClick(selectedForest, selectedForest.id);
        }
    }
    // useEffect(() => {
    //     // getAllTrees()
    //     // if(5000 > allTrees.list.length){
    //     //     setTimeout(() => {
    //     //         getAllTrees()
    //     //     }, 2000);
    //     // }
    // },[])

    useEffect(() => {

        getForestsAndTreesDetails()
        if (path.tree_id) {
            let pathType = currentPath?.pathname?.split('/')?.[1];
            if (pathType === "forest") {
                let data = {
                    forestUniqueId: path.id
                }
                circleClick(data)
            } else if (pathType === "tree") {
                let data = {
                    treeUniqueId: path.tree_id,
                }
                markerClick(data)
            }
        }
    }, [])


    useEffect(() => {
        if (props?.searchValue?.value) {
            getForestsBySearchValue(props?.searchValue)
        }
        // eslint-disable-next-line
    }, [props?.searchValue?.value])


    const getForestsAndTreesDetails = async () => {
        await client.query({
            query: GET_ALL_FORESTS_AND_TREES,
            // variables: {
            //     offset,
            //     limit
            // }
        }).then(res => {
            const allForest = res?.data?.allForests;
            setForestsAndTreesList({
                ...forestsAndTreesList,
                data: [...allForest?.nodes],
                totalCount: allForest?.totalCount,
                center: {
                    lat: Number(allForest?.nodes?.[0]?.forestGeoLat),
                    lng: Number(allForest?.nodes?.[0]?.forestGeoLong),
                }
            });
        }).catch(err => {
            console.log(err)
        })
    }

    const getForestsBySearchValue = async (val) => {

        setSelectedNode({
            marker: val?.forestByForestId?.nodeId ? val?.nodeId : null,
            circle: val?.findByNodeId
        })

        // if (val?.forestByForestId?.nodeId) {
        //     map.setZoom(initialZoom);
        // }
        // else {
        //     map.setZoom(initialZoom + 1);
        // }
        map.panTo({
            lat: Number(val?.lat),
            lng: Number(val?.lng)
        })

        // await client.query({
        //     query: GET_FOREST_DETAILS_BY_ID,
        //     variables: {
        //         nodeId: val?.findByNodeId
        //     }
        // }).then(res => {
        //     console.log(val)
        //     const forest = res?.data?.forest;
        //     map.setZoom(initialZoom + 1);
        //     map.panTo({
        //         lat: Number(forest?.forestGeoLat),
        //         lng: Number(forest?.forestGeoLong)
        //     })
        // }).catch(err => {
        //     console.log(err)
        // })
    }

    const onIdle = (map) => {
        // console.log('idle', map);

        // let mapZoom = map.getZoom();
        // if(mapZoom > 14){
        //     if(zoomLevel){
        //         setZoomLevel(false)
        //     }
        // }else{
        //     if(!zoomLevel){
        //         setZoomLevel(true)
        //     }
        // }
        // if (mapZoom < initialZoom && pagination.offset < forestsAndTreesList?.totalCount) {
        //     getForestsAndTreesDetails(pagination.offset, pagination.limit)
        // }
        // if (mapZoom > 6 && !toggleView) {
        //     setToggleView(true)
        // }
        // else if (toggleView && mapZoom <= 6) {
        //     setToggleView(false)
        // }
    };

    const onClickMap = (e) => {
        let findPopup = document?.getElementsByClassName('gm-style-iw-t');
        if (findPopup?.length) {
            //google info window popup
            findPopup[0].style.display = "none"
            if (e.placeId) {
                findPopup[0].style.display = "block"
            }
            // //custom info window popup
            closeInfoWindow()
        }
    }

    const closeInfoWindow = () => {
        if (infoWindow?.getMap()) {
            infoWindow.close();
        }
    }

    const modalStyle = {
        '& .MuiPaper-root.MuiDialog-paper': {
            minWidth: { sm: "90vw", md: '78vw' },
            minHeight: '94vh',
            borderRadius: '12px',
            padding: 0,
            overflowX: 'auto'
        },
        '& .MuiDialogContent-root': {
            padding: '0 !important',
            overflowX: 'auto',
            '::-webkit-scrollbar': {
                display: 'none'
            },
            'scrollbarWidth': 'none',
            '-ms-overflow-style': 'none'
        }
    }
    const modalStyleForest = {
        // '& .MuiPaper-root.MuiDialog-paper': {
        //     borderRadius: '12px',
        //     padding: 0,
        //     overflowX: 'auto'
        // },
        '& .MuiDialogContent-root': {
            padding: '0 !important',
            overflowX: 'auto',
            '::-webkit-scrollbar': {
                display: 'none'
            },
            'scrollbarWidth': 'none',
            '-ms-overflow-style': 'none'
        }
    }

    const dialogComponent = (component, type) => {
        dialog.setDialog({
            ...dialog,
            open: true,
            body: component,
            container: document.querySelector('#map'),
            // sx: 
            sx: type === 'forest' ? modalStyleForest : modalStyle
        })
    }

    const circleClick = async (data) => {

        setSelectedNode({
            marker: null,
            circle: data?.nodeId ?? ""
        })

        // dialogComponent(<TreeInfo handleCloseTreeInfo={closeDialog} />, 'forest')
        // dialogComponent(<InfoDetails loading showVideo />)
        const { forestUniqueId: uniqId, id } = data;
        await client.query({
            query: GET_FOREST_DETAILS_BY_ID,
            fetchPolicy: "network-only",
            variables: {
                uniqId
            }
        }).then(res => {

            const forest = res?.data?.allForests?.nodes?.[0];
            const speciesCount = res?.data?.allForests?.nodes?.[0]?.totalSpeciesPlanted;
            const forestImages = res?.data?.allForests?.nodes?.[0]?.forestAssertsByForestId?.nodes;
            const construstForestURL = (url) => url?.split(',');
            if (forest) {
                // dialogComponent(
                //     <TreeInfo
                //         treeUrl={forest?.forestUrl}
                //         treeName={forest?.forestName}
                //         treeUniqueId={forest?.forestUniqueId}
                //         isAlive={true}
                //         treeHeight={forest?.forestTreeHeight}
                //         treeDia={forest?.forestTreeDia}
                //         treeAge={forest?.forestTreeAge}
                //         treeCorban={Math.round(forest?.forestCarbonoffset * 100) / 100}
                //         treeOxygen={Math.round(forest?.forestOxygen * 100) / 100}
                //         treeLat={forest?.forestGeoLat}
                //         treeLong={forest?.forestGeoLong}

                //         isGifted={forest?.forestSponsorsByForestId?.nodes.length}
                //         gifterName={forest.forestSponsorsByForestId?.nodes[0]?.sponsorBySponsorId?.sponsorName}
                //         gifterUrl={forest.forestSponsorsByForestId?.nodes[0]?.sponsorBySponsorId?.sponsorLogo}
                //         giftDesignation={forest?.sponsorBySponsorId?.nodes[0]?.designation}
                //         giftedOrganizationName={forest?.sponsorBySponsorId?.nodes[0]?.organizationName}
                //         giftedOrgUrl={forest?.sponsorBySponsorId?.nodes[0]?.orgLogoUrl}
                //         giftedDate={forest?.sponsorBySponsorId?.nodes[0]?.updatedAt}

                //         timelineOrder={timelineOrder}
                //         timelineData={forest?.forestPlantTimelinesByPlantId?.nodes}

                //         url={path.tree_id && !data?.nodeId ? window.location.href : window.location.origin+"/tree/"+forest.forestByForestId.forestUniqueId+"/"+forest.treeUniqueId}

                //         handleOrderTimeline={handleOrderTimeline}
                //         handleLocationTree={handleLocationTree}
                //         handleCloseTreeInfo={closeDialog}
                //     />
                // )
                dialogComponent(
                    <InfoDetails
                        uniqueId={uniqId}
                        title={forest?.forestName}
                        logo=""
                        description={forest?.forestDesc ?? ""}
                        showVideo={false}
                        videos={forest?.forestUrl ? construstForestURL(forest?.forestUrl) : []}
                        subTitle="Stats"
                        info={[
                            { label: 'Oxygen Generated', value: Math.round(forest?.forestOxygen / 1000).toLocaleString(), unit: "kt" },
                            { label: 'Carbon Offset', value: Math.round(forest?.forestCarbonoffset / 1000).toLocaleString(), unit: "kt" },
                            { label: 'Trees Planted', value: forest?.totalTrees },
                            { label: 'Species Planted', value: speciesCount ? speciesCount : 0 },
                        ]}
                        images={forestImages}
                        width={'304px'}
                        onClose={closeDialog}
                        sponsoredName={forest?.forestSponsorsByForestId?.nodes?.[0]?.sponsorBySponsorId?.sponsorName}
                        sponsoredLogo={forest?.forestSponsorsByForestId?.nodes?.[0]?.sponsorBySponsorId?.sponsorLogo}
                        share={true}
                        url={data?.nodeId ? window.location.origin+"/forest/"+forest.forestUniqueId : window.location.href}
                    />, 'forest'
                )
                // setTimeout(() => {
                //     if(map?.setCenter){
                //         map.setCenter({
                //             lat: Number(forest?.forestGeoLat),
                //             lng: Number(forest?.forestGeoLong),
                //         });
                //     }
                // }, 10000);

            } else {
                dialog.setDialog({
                    open: false
                })
            }

        }).catch(err => {
            console.log(err)
        })
    }

    const markerClick = async (data, circleNodeId) => {
        setSelectedNode({
            marker: data?.nodeId ?? "",
            // circle: circleNodeId
        })

        // dialogComponent(<TreeInfo handleCloseTreeInfo={closeDialog} />, 'tree')
        // dialogComponent(<InfoDetails loading showVideo />)
        let uniqId = data.treeUniqueId;
        await client.query({
            query: GET_TREES_BY_NODEID(timelineOrder),
            fetchPolicy: "network-only",
            variables: {
                uniqId
            }
        }).then(res => {
            const forestTree = res?.data?.allForestTrees?.nodes?.[0];
            const forestId = res?.data?.allForestTrees?.nodes[0]?.forestByForestId?.forestUniqueId
            if (forestTree) {
                dialogComponent(
                    <TreeInfo
                        treeName={forestTree?.forestTreeName}
                        treeUniqueId={forestTree?.treeUniqueId}
                        isAlive={forestTree?.isActive}
                        treeHeight={forestTree?.forestTreeHeight}
                        treeDia={forestTree?.forestTreeDia}
                        treeAge={forestTree?.forestTreeAge}
                        treeCorban={Math.round(forestTree?.forestTreeCarbonoffset * 100) / 100}
                        treeOxygen={Math.round(forestTree?.forestTreeOxygen * 100) / 100}
                        treeLat={forestTree?.forestTreeGeoLat}
                        treeLong={forestTree?.forestTreeGeoLong}

                        isGifted={forestTree?.giftForestPlantsByGiftTreeId?.nodes.length}
                        gifterName={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.name}
                        gifterUrl={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.userAssetUrl}
                        giftDesignation={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.designation}
                        giftedOrganizationName={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.organizationName}
                        giftedOrgUrl={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.orgLogoUrl}
                        giftedDate={forestTree?.giftForestPlantsByGiftTreeId?.nodes[0]?.allocatingOn}

                        timelineOrder={timelineOrder}
                        timelineData={forestTree?.forestPlantTimelinesByPlantId?.nodes}

                        url={path.tree_id && !data?.nodeId ? window.location.href : window.location.origin+"/tree/"+forestId+"/"+forestTree.treeUniqueId}

                        handleOrderTimeline={handleOrderTimeline}
                        handleLocationTree={handleLocationTree}
                        handleCloseTreeInfo={closeDialog}
                    />, 'tree')
                // dialogComponent(
                //     <InfoDetails
                //         status={forestTree?.treeStatusMasterByTreeStatusId?.status}
                //         uniqueId={forestTree.treeUniqueId}
                //         title={forestTree?.forestTreeName}
                //         description={""}
                //         subTitle="Stats"
                //         geoLocation={{
                //             lat: forestTree?.forestTreeGeoLat,
                //             long: forestTree?.forestTreeGeoLong
                //         }}
                //         info={[
                //             { label: 'Tree Height', value: forestTree?.forestTreeHeight, unit: "feet" },
                //             { label: 'Tree Dia', value: forestTree?.forestTreeDia, unit: "inch" },
                //             { label: 'Tree Age', value: forestTree?.forestTreeAge, unit: "days" },
                //             { label: 'Oxygen Generated', value: Math.round(forestTree?.forestTreeOxygen), unit: "kgs" },
                //             { label: 'Carbon Offset', value: Math.round(forestTree?.forestTreeCarbonoffset), unit: "kgs" },
                //         ]}
                //         width={'304px'}
                //         onClose={closeDialog}
                //         // status={`${forestTree?.forestTreePetname ? forestTree?.forestTreePetname : '-'}`}
                //         showImage
                //         showSponsorName={false}
                //         sponsoredName={forestTree?.forestByForestId?.forestSponsorsByForestId?.nodes[0]?.sponsorBySponsorId?.sponsorName}
                //         sponsoredLogo={forestTree?.forestByForestId?.forestSponsorsByForestId?.nodes[0]?.sponsorBySponsorId?.sponsorForestLogo}
                //         avatarUrl={forestTree?.treeAssertsByTreeId?.nodes?.[0]?.url}
                //         avatarName={forestTree?.plantedBy}
                //         share={true}
                //         url={path.tree_id && !data?.nodeId ? window.location.href : window.location.origin+"/tree/"+forestTree.forestByForestId.forestUniqueId+"/"+forestTree.treeUniqueId}
                //     />
                // )
            } else {
                dialog.setDialog({
                    open: false
                })
            }

        }).catch(err => {
            console.log(err)
            dialog.setDialog({
                open: false
            })
        })
    }

    const renderStatus = (status) => {
        if (status === Status.FAILURE) return <CustomSkeleton component="div">Something went wrong!</CustomSkeleton>;
        return <CustomSkeleton component="div" variant="rectangular">
            <img src={Logo} alt={"logo"} />
        </CustomSkeleton>;
    };

    const markerIcon = useCallback((url) => ({
        url,
        // anchor: new window.google.maps.Point(0,0),
        // origin: new window.google.maps.Point(0,0),
        // size: new window.google.maps.Size(50, 50)

        // eslint-disable-next-line
    }), [selectedNode.marker])

    const areaClick = (data, forestId) => {
        map.setCenter({
            lat: Number(data?.forestGeoLat),
            lng: Number(data?.forestGeoLong),
        });
        // map.setZoom(16);
        map.setOptions({
            ...props,
            zoom: 16,
            maxZoom: 18
        })
        setSelectedForest(data);
        getAllTrees(forestId)
    }

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Stas data={forestDetails} />
            {(forestsAndTreesList.data.length > 0) ?
                <RenderMap
                    center={forestsAndTreesList.center}
                    initialZoom={initialZoom}
                    minZoom={minZoom}
                    maxZoom={allTrees?.maxZoom}
                    onIdle={onIdle}
                    onClickMap={onClickMap}
                    infoWindow={infoWindow}
                    mapStyles={mapStyles}
                    map={map}
                    setMap={setMap}
                    allTrees={allTrees}
                    forestsAndTreesList={forestsAndTreesList}
                    renderStatus={renderStatus}
                    selectedNode={selectedNode}
                    setInfoWindow={setInfoWindow}
                    circleClick={circleClick}
                    markerIcon={markerIcon}
                    markerClick={markerClick}
                    areaClick={areaClick}
                    setAllTrees={setAllTrees}
                /> :
                <CustomSkeleton component="div" variant="rectangular">
                    <img src={Logo} alt={"logo"} />
                </CustomSkeleton>
            }
            {allTrees.list.length > 0 &&
                <FooterButton clusterZoom={allTrees?.clusterZoom} backToForestBtn={backToforest} backToCluster={backToCluster} />
            }
        </div>
    )
}