import React, { useEffect, useState } from "react";
import { CustomMap, SEO } from "../../components";
import { useApolloClient } from "@apollo/client";
import {
  GET_ALL_FORESTS,
  GET_FOREST_SEO_BY_UNIQUE_ID,
  GET_TREE_SEO_BY_FOREST_ID,
  GET_TREE_SEO_BY_UNIQUE_ID,
} from "../../graphql/queries";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Popover from "@mui/material/Popover";
import styled from "@mui/material/styles/styled";
import { Typography, Button, IconButton, Divider } from "@mui/material";
// import TextField from '@mui/material/TextField';
// import { InputField } from '@crayond_dev/ui_input-field';
// import { MobileInput } from '@crayond_dev/ui_mobile-input';
// import { countryCode } from '../../utils/mobileCode';
import {
  ContributionDetails,
  ContributionCertificateScreen,
  Contribution,
} from "../../screens";
import { Routes } from "../../router/routes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const ContributionPopUp = styled(Popover)({});

export const Home = (props) => {
  const client = useApolloClient();

  const [state, setState] = useState({
    allForestDetails: [],
  });

  const history = useHistory();
  let path = useParams();

  const [anchorEl, setAnchorEl] = React.useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [letsPlant, setLetsPlant] = React.useState(false);
  const [proceedPay, setProceedPay] = React.useState(false);

  // const handleClose = (event) => {
  //   if (anchorEl && anchorEl.contains(event.currentTarget)) {
  //     return;
  //   }

  //   setAnchorEl(null);
  // };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [seoData, setSeoData] = useState({
    title: "Be The Tree Hugger",
    description:
      "Be The Tree Hugger, is your go-to hub for eco-conscious living. Discover practical tips, inspiring content, and a community dedicated to preserving our planet. Join us in our mission to cultivate a greener, more sustainable world.",
    type: "website",
    imageUrl: `${window.origin}/images/bth_og_mirchi.jpg`,
  });

  const onChangeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const location = useLocation();

  useEffect(() => {
    getAllForests();
    getSeoDetails();
    // eslint-disable-next-line
  }, []);

  const getAllForests = async () => {
    await client
      .query({
        query: GET_ALL_FORESTS,
      })
      .then((res) => {
        // console.log(res)
        onChangeState("allForestDetails", res?.data?.allForests?.nodes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSeoDetails = () => {
    let temp = location.pathname.split("/").filter(Boolean);
    let type, unique_id, foerst_unique_id;
    if (location.pathname.includes("forest")) {
      type = temp[0];
      unique_id = temp[1];
    } else {
      type = temp[0];
      foerst_unique_id = temp[1];
      unique_id = temp[2];
    }
    if (!type || !unique_id || !foerst_unique_id) return;
    let query = GET_FOREST_SEO_BY_UNIQUE_ID;
    if (type == "tree") {
      query = GET_TREE_SEO_BY_FOREST_ID;
    }
    client
      .query({
        query,
        variables: {
          unique_id,
          foerst_unique_id,
        },
      })
      .then(({ data }) => {
        const getTitle = ({ name, forest, sponsors }) => {
          if (type !== "tree") {
            return `${name}, sponsored by ${sponsors?.nodes
              .map((i) => i.sponsorBySponsorId.sponsorName)
              .join(",")}`;
          }
          return `${name} tree planted in ${
            forest?.name
          }, sponsored by ${sponsors?.nodes
            .map((i) => i?.sponsorBySponsorId?.sponsorName)
            .join(",")}`;
        };
        const getDescription = ({
          total_trees,
          total_species_planted,
          oxygen_produced,
          carbon_offset,
          species,
        }) => {
          if (type !== "tree") {
            return `Rich treasure with ${total_trees} trees, ${total_species_planted} species, generated ${
              Number(oxygen_produced) / 1000
            } kt of oxygen and has offset ${
              Number(carbon_offset) / 1000
            } kt of carbon.`;
          }
          return `Belonging to ${species?.name} species, generated ${
            Number(oxygen_produced) / 1000
          } kt of oxygen and has offset ${
            Number(carbon_offset) / 1000
          } kt of carbon.`;
        };
        let seoData = data.seoData;
        if (type == "tree") {
          seoData = data?.seoData?.nodes[0];
        }
        let {
          name,
          forest,
          total_trees,
          total_species_planted,
          oxygen_produced,
          carbon_offset,
          sponsors,
          species,
        } = seoData;       
        setSeoData({
          ...seoData,
          title: getTitle({ name, forest, sponsors }),
          description: getDescription({
            total_trees,
            total_species_planted,
            oxygen_produced,
            carbon_offset,
            species,
          }),
          imageUrl: sponsors?.nodes?.length
            ? sponsors.nodes?.[0]?.sponsorBySponsorId?.sponsorOgImageUrl
            : `${window.origin}/images/bth_og_mirchi.jpg`,
        });
      })
      .catch(console.error);
  };

  const letsPlantFunc = () => {
    setAnchorEl(null);
    setLetsPlant(true);
  };

  const HandlePtoPCancel = () => {
    setAnchorEl(true);
    setLetsPlant(false);
  };

  const handleProcessAndPay = () => {
    setisLoading(true);
    setTimeout(() => {
      setisLoading(false);
      setLetsPlant(false);
      setProceedPay(true);
    }, 2000);
  };

  const HandleBackToBTH = () => {
    setAnchorEl(null);
    setLetsPlant(false);
    setProceedPay(false);
    history.push(Routes.home);
  };

  const handleClick = (event) => {
    setAnchorEl(null);
    history.push(Routes.home);
  };

  useEffect(() => {
    const isInContributionRoute = location.pathname.includes("/contribution");

    if (isInContributionRoute) {
      setAnchorEl(true);
    } else {
      setAnchorEl(false);
    }
  }, [location?.pathname]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SEO {...seoData} />
      <div
        style={{
          width: "100%",
          height: "100%",
          // height: 'calc(100vh - 64px)',
        }}
      >
        {letsPlant !== true && proceedPay !== true && (
          <CustomMap
            forestDetails={state?.allForestDetails}
            searchValue={props?.searchValue}
          />
        )}

        {/* detail collect screen */}
        {letsPlant === true && proceedPay !== true && (
          <ContributionDetails
            handleProcessAndPay={handleProcessAndPay}
            HandlePtoPCancel={HandlePtoPCancel}
          />
        )}
        {/* certificate screen */}
        {proceedPay === true && (
          <ContributionCertificateScreen HandleBackToBTH={HandleBackToBTH} />
        )}
      </div>

      <ContributionPopUp
        id={id}
        open={open}
        anchorEl={anchorEl}
        // onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "12px !important",
            // border: '1px solid',
          },
          backdropFilter:
            location.pathname.includes("/contribution") === true
              ? "blur(2px)"
              : "blur(0px)",
        }}
      >
        <Contribution letsPlantFunc={letsPlantFunc} handleClick={handleClick} />
      </ContributionPopUp>
    </>
  );
};
