import React from "react";

export const MinusIcon = (props) => {
    const width = props?.width ? props?.width : 9.5;
    const height = props?.height ? props?.height : 1.188;
    const color = props?.color ? props?.color : '#6f6f6f';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 9.5 1.188"
            {...props}
        >
            <path id="Path_98089" data-name="Path 98089" d="M60.349,62.105H52.037a.594.594,0,0,1,0-1.187h8.313a.594.594,0,1,1,0,1.188Z" transform="translate(-51.443 -60.918)" fill={color} />
        </svg>
    );
};
