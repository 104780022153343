import React, { useEffect } from 'react';
import { CustomControls } from './customControls';
// import Typography from '@mui/material/Typography';
// import styled from '@mui/material/styles/styled';
import './index.css';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import TreeHuggerGreen from '../../assets/tree_hugger_green.svg';
// const Title = styled(Typography)({
//     fontWeight: 500,
//     fontSize: 18,
//     color: '#324052',
//     marginLeft: '16px'
// });

let icon = window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="36" viewBox="0 0 10.683 15.906">
<g id="Tree_hugger_green_" data-name="Tree hugger (green)" transform="translate(-1356 -912)">
  <path id="Path_11" data-name="Path 11" d="M10.682,7.444c0,4.111-5.352,8.462-5.352,8.462S0,11.555,0,7.444,1.856,0,5.306,0s5.376,3.333,5.376,7.444" transform="translate(1356 912)" fill="#337630"/>
  <path id="Path_12" data-name="Path 12" d="M5.567,9.886s-3.04.243-3.256-2.153c-.139-1.608.759-2.014.759-2.014s.54-2.362,2.211-2.435C7.815,3.174,7.889,5.91,7.889,5.91s2.1,3.673-2.322,3.976" transform="translate(1356 912)" fill="#fff"/>
  <line id="Line_1" data-name="Line 1" y2="1.803" transform="translate(1361.295 921.983)" fill="#fff"/>
  <path id="Path_13" data-name="Path 13" d="M5.3,12.25c-.432,0-.625-.233-.625-.465v-2.3l.4.028A1.012,1.012,0,0,1,5.3,9.484h.23l.395.009v2.292c0,.232-.193.465-.625.465" transform="translate(1356 912)" fill="#337630"/>
  <path id="Path_14" data-name="Path 14" d="M5.045,9.885s.112.008.25.008.25-.006.25-.006v1.9c0,.05-.112.09-.25.09s-.25-.04-.25-.09Zm-.749-.8v2.7a.885.885,0,0,0,1,.84.885.885,0,0,0,1-.84V9.1l-.788.039c-.006,0-.1.005-.212.005s-.19-.005-.2-.006Z" transform="translate(1356 912)" fill="#fff"/>
  <line id="Line_2" data-name="Line 2" y2="3.231" transform="translate(1361.295 918.502)" fill="#fff"/>
  <path id="Path_15" data-name="Path 15" d="M5.3,9.982c-.138,0-.25-.01-.25-.01V6.506c0-.091.112-.165.25-.165s.25.074.25.165v3.47s-.112.006-.25.006" transform="translate(1356 912)" fill="#337630"/>
  <line id="Line_3" data-name="Line 3" x2="1.421" y2="1.765" transform="translate(1359.841 919.167)" fill="#fff"/>
  <path id="Path_16" data-name="Path 16" d="M5.263,9.182a.25.25,0,0,1-.2-.093L3.647,7.324a.25.25,0,1,1,.389-.314L5.458,8.775a.251.251,0,0,1-.2.407" transform="translate(1356 912)" fill="#337630"/>
  <line id="Line_4" data-name="Line 4" x1="0.897" y2="1.162" transform="translate(1361.327 920.148)" fill="#fff"/>
  <path id="Path_17" data-name="Path 17" d="M5.327,9.56a.249.249,0,0,1-.2-.4l.9-1.163a.25.25,0,0,1,.4.306l-.9,1.161a.248.248,0,0,1-.2.1" transform="translate(1356 912)" fill="#337630"/>
  <path id="Path_18" data-name="Path 18" d="M6.977,5.792a.252.252,0,0,1-.249-.223,1.732,1.732,0,0,0-.652-1.1.25.25,0,1,1,.3-.4,2.223,2.223,0,0,1,.853,1.446A.25.25,0,0,1,7,5.791H6.977" transform="translate(1356 912)" fill="#337630"/>
  <rect id="Rectangle_42" data-name="Rectangle 42" width="10.683" height="15.906" transform="translate(1356 912)" fill="none"/>
</g>
</svg>
`);

export const MapComponent = ({
    map,
    center,
    setMap,
    onIdle,
    onClick,
    // zoom_changed,
    children,
    duration,
    markerList,
    markerClick,
    setAllTrees,
    clusterZoom,
    ...props
}) => {

    const ref = React.useRef(null);
    // const markerClis = React.useRef(null);

    const [markerClis, setMarkerClis] = React.useState();

    const onClusterClickHandler = (event, cluster, map) => {
        setAllTrees({
            list: cluster?.markers??[],
            offset: 1,
            clusterZoom: true,
            maxZoom: 25
        })
        map.setOptions({
            ...props,
            maxZoom: 55
        })
    }

    useEffect(() => {
        if(markerClis){
            markerClis.clearMarkers();
        }  
        if(markerList?.length > 0){

            if(!clusterZoom){
                let markers = markerList && markerList.map((location) => {
                    return new window.google.maps.Marker({
                    // label: { text: String("123") },
                    position: {
                        "lat": Number(location?.forestTreeGeoLat),
                        "lng": Number(location?.forestTreeGeoLong)
                    },
                    icon: {
                        url: `data:image/svg+xml;base64,${icon}`,
                        // scaledSize: new window.google.maps.Size(35, 75)
                    },
                    data: location
                })
                })
                
        
                // Add click event listeners to each marker
                markers.forEach((marker) => {
                    marker.addListener('click', function() {
                        // Handle the marker click event
                        markerClick(marker.data)
                    });
                });
                let options = {
                    zoomOnClick: false,
                    averageCenter: false,
                    maxZoom: 30, // Set the maximum zoom level
                    gridSize: 60, // Set the grid size for clustering
                }

                const svg = ((countLevel) => {
                    let color = "green";
                    if(countLevel > 1000){
                        color = "#EB5B34"
                    }else if(countLevel < 500){
                        color = "#478A44"
                    }else if(countLevel < 999){
                        color = "#677CFC"
                    }

                    return window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 320">
                        <circle fill="${color}" cx="160" cy="160" opacity=".2" r="85" />    
                        <circle fill="${color}" cx="160" cy="160" opacity=".4" r="70" />    
                        <circle fill="${color}" cx="160" cy="160" opacity=".9" r="55" />    
                    </svg>`)
                });

                const renderer = {
                    render: ({ count, position, _applyStyle }) =>
                      new window.google.maps.Marker({
                        // label: { text: String(count), color: "white", fontSize: "10px" },
                        position,
                        icon: {
                            url: `data:image/svg+xml;base64,${svg(count)}`,
                            scaledSize: new window.google.maps.Size(75, 75)
                        },
                        label: {
                            text: " ",
                            color: "#fff",
                            fontSize: "12px",
                        },
                        // adjust zIndex to be above other markers
                        zIndex: 1000,
                      }),
                      
                  };
                const algorithmOptions = {
                    maxZoom: 18
                }
                  
                let clusterer = new MarkerClusterer({ map, markers, onClusterClick: onClusterClickHandler, algorithmOptions, renderer});

                setMarkerClis(clusterer)
            }else{
                let center = {
                    "lat": Number(markerList?.[0]?.data?.forestTreeGeoLat),
                    "lng": Number(markerList?.[0]?.data?.forestTreeGeoLong)
                }
                map.setCenter(center);
                map.setZoom(20);

                const markers = markerList.map((markerData) => {
                    return new window.google.maps.Marker({
                        position: {
                            "lat": Number(markerData?.data?.forestTreeGeoLat),
                            "lng": Number(markerData?.data?.forestTreeGeoLong)
                        },    
                        data: markerData?.data,
                        // title: 'Marker Title',
                        icon: {
                            url: `data:image/svg+xml;base64,${icon}`
                        }
                    });
                  });

                
                   // Add click event listeners to each marker
                markers.forEach((marker) => {
                    marker.addListener('click', function() {
                        // Handle the marker click event
                        markerClick(marker.data)
                    });
                });

                // Add the marker to the current MarkerClusterer
                markerClis.addMarkers(markers);
            }

            
        }
    },[markerList])

    useEffect(() => {
        if (ref?.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {}));
        }

        if (map) {
            map.setOptions(props);
            if (map && (center?.lat && center?.lng) && (map?.getCenter()?.lat() !== center?.lat && map?.getCenter()?.lng() !== center?.lng)) {
                map.setCenter(center)
            }
            // map.panTo(props?.center);
            map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
                document.querySelector(".controls")
            );
            map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(
                document.querySelector(".map-types")
            );
            map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(
                document.querySelector(".fullscreen-control")
            );
            map.controls[window.google.maps.ControlPosition.BOTTOM_CENTER].push(
                document.querySelector(".version")
            );

            //find whether document is full screen or not
            let mapWrapper = document?.getElementsByClassName('map-wrapper')?.[0];
            document.onwebkitfullscreenchange =
                document.onmsfullscreenchange =
                document.onmozfullscreenchange =
                document.onfullscreenchange =
                function () {
                    if (isFullscreen(mapWrapper)) {
                        mapWrapper.classList.add("is-fullscreen");
                    } else {
                        mapWrapper.classList.remove("is-fullscreen");
                    }
                };
        }
        // eslint-disable-next-line
    }, [ref, map, center]);

    //useEffect fir event handlers
    useEffect(() => {
        if (map) {
            ["idle", "click"].forEach((eventName) =>
                window.google.maps.event.clearListeners(map, eventName)
            );

            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }

            if (onClick) {
                map.addListener("click", (e) => onClick(e));
            }

        }
    }, [map, onIdle, onClick]);

    const zoomIn = () => {
        map.setZoom(map.getZoom() + 1);
    }

    const zoomOut = () => {
        map.setZoom(map.getZoom() - 1);
    }

    const fullScreenControl = () => {
        const elementToSendFullscreen = document?.getElementsByClassName('map-wrapper')?.[0];
        if (!isFullscreen(elementToSendFullscreen)) {
            requestFullscreen(elementToSendFullscreen);
        }
    }

    // const ExitFullScreen = () => {
    //     exitFullscreen();
    // }

    const isFullscreen = (element) => {
        return (
            (document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullscreenElement) === element
        );
    }

    const requestFullscreen = (element) => {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.msRequestFullScreen) {
            element.msRequestFullScreen();
        }
    }

    // const exitFullscreen = () => {
    //     if (document.exitFullscreen) {
    //         document.exitFullscreen();
    //     } else if (document.webkitExitFullscreen) {
    //         document.webkitExitFullscreen();
    //     } else if (document.mozCancelFullScreen) {
    //         document.mozCancelFullScreen();
    //     } else if (document.msExitFullscreen) {
    //         document.msExitFullscreen();
    //     }
    // }

    const getCurrentLocation = () => {
        // Try HTML5 geolocation.
        // let infoWindow = new window.google.maps.InfoWindow();
        let infoWindow = props?.infoWindow;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    infoWindow.setPosition(pos);
                    infoWindow.setContent("Location found.");
                    infoWindow.open(map);
                    map.setCenter(pos);
                },
                () => {
                    handleLocationError(true, infoWindow, map.getCenter());
                }
            );
        } else {
            // Browser doesn't support Geolocation
            handleLocationError(false, infoWindow, map.getCenter());
        }
    }

    const handleLocationError = (browserHasGeolocation, infoWindow, pos) => {
        infoWindow.setPosition(pos);
        infoWindow.setContent(
            browserHasGeolocation
                ? "Error: Provide permission to location"
                : "Error: Your browser doesn't support geolocation."
        );
        infoWindow.open(map);
    }

    const changeMapTypes = (value) => {
        map.setMapTypeId(value);
    }

    return (
        <div className='map-wrapper'>

            <div ref={ref} id="map" style={{ flexGrow: "1", height: "100%" }}>
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        // set the map prop on the child component
                        return React.cloneElement(child, { map });
                    }
                })}
            </div>
            {/* Hide controls until they are moved into the map */}
            <div style={{ display: 'none' }}>
                <CustomControls
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    fullScreenControl={fullScreenControl}
                    getCurrentLocation={getCurrentLocation}
                    changeMapTypes={changeMapTypes}
                />
            </div>
        </div>
    )
}