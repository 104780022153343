import React from 'react';
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";



const useStyles = makeStyles((theme) => ({
    flootingBtn: {
        padding: "12px",
        background: "rgba(0,0,0,0.07)",
        borderRadius: "14px",
        position: "fixed",
        zIndex: 1000,
        bottom: "20px",
        textAlign: "center",
        left: "40%",
        [theme.breakpoints.down("600")]: {
            width: "100%",
            bottom: 0,
            borderRadius: 0,
            left: "0%",

        }
    },
    allForestBtn: {
        fontWeight: "bold",
        borderRadius: "8px",
        border: "1px solid #17970E",
        color: "#17970E"
    },
    viewClusterBtn: {
        marginLeft: 10,
        backgroundColor: "#17970E",
        color: "#fff",
        fontWeight: "bold",
        borderRadius: "8px",
        "&:hover":{
            backgroundColor: "#17970E"
        }
    }
}));


export const FooterButton = ({
    backToForestBtn,
    backToCluster,
    clusterZoom,
}) => {
    const classes = useStyles();

    return(
        <div className={classes.flootingBtn}>
            <Button onClick={backToForestBtn} className={classes.allForestBtn} variant="contained" color={"inherit"} >{"View All Forests"}</Button>
            {clusterZoom && 
                <Button className={classes.viewClusterBtn} onClick={backToCluster} variant="contained" color={"primary"} >{"View Other Clusters"}</Button>            
            }
        </div>
    )
}