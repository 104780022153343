import React from 'react'

const ShareIcon = (props) => {
    const { color } = props
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill={color}>
                <path d="M11.4375 0.28125C10.1713 0.28125 9.14062 1.31156 9.14062 2.57812C9.14062 2.71347 9.15459 2.8452 9.17715 2.97418L4.16428 5.48062C3.83886 5.30362 3.46601 5.20312 3.07031 5.20312C1.80408 5.20312 0.773438 6.23344 0.773438 7.5C0.773438 8.76656 1.80408 9.79688 3.07031 9.79688C3.5325 9.79688 3.96246 9.65854 4.32321 9.42261L9.15152 12.2097C9.14508 12.2797 9.14062 12.3503 9.14062 12.4219C9.14062 13.6884 10.1713 14.7188 11.4375 14.7188C12.7037 14.7188 13.7344 13.6884 13.7344 12.4219C13.7344 11.1553 12.7037 10.125 11.4375 10.125C10.7161 10.125 10.0722 10.4604 9.65076 10.9825L5.18198 8.40298C5.30102 8.12567 5.36719 7.82041 5.36719 7.5C5.36719 7.12947 5.27696 6.78034 5.12045 6.47012L9.76547 4.14761C10.1848 4.59402 10.7783 4.875 11.4375 4.875C12.7037 4.875 13.7344 3.84469 13.7344 2.57812C13.7344 1.31156 12.7037 0.28125 11.4375 0.28125Z" fill={color} />
            </svg>
        </>
    )
}

export default ShareIcon