import { Typography, Button } from "@mui/material";


export default function Contribution(props) {

    const { letsPlantFunc, handleClick } = props;

    return (
        <div style={{ width: '328px' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '40px' }}>
                <img alt="logo" src="/RTI.png" style={{ height: "160px", objectFit: "contain" }} />
            </div>
            <div style={{ padding: "16px" }}>
                <Typography sx={{
                    color: "#02111A",
                    textAlign: "center",
                    fontFamily: "crayond_regular",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                    letterSpacing: "-0.18px;",

                }}>{"Together let's contribute to the mission of making this planet green & clean."}</Typography>
            </div>


            <div style={{
                margin: "auto",
                width: "296px",
                height: "56px",
                flexShrink: 0,
                borderRadius: "12px",
                background: "rgba(0, 0, 0, 0.04)",
                padding: "10px",
                display: 'flex',
                marginTop: '24px'
            }}>
                <img alt="logo" src="/info.svg" style={{ height: '16px' }} />
                <Typography sx={{
                    color: "#6D6D6D",
                    fontFamily: "crayond_regular",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "20px",
                    marginLeft: "8px"
                }}>Your contributions are exempted under section 80G of income tax.</Typography>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <Button
                    sx={{
                        borderRadius: "8px",
                        background: "#17970E",
                        width: "296px",
                        height: "40px",
                        marginBottom: '12px',
                        marginTop: "24px",
                        ':hover': {
                            background: "#17970E",
                        }
                    }}
                    variant={"contained"}
                    color={"primary"}
                    onClick={letsPlantFunc}
                >
                    <Typography sx={{
                        color: "#FFF",
                        fontFamily: "crayond_regular",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px",
                        textTransform: 'none'
                    }}>Let's Plant</Typography>
                </Button>

                <Button
                    sx={{
                        borderRadius: "8px",
                        border: "1px solid rgba(0, 0, 0, 0.08)",
                        background: "#fff",
                        width: "296px",
                        height: "40px",
                        marginBottom: '24px',
                        boxShadow: 'none',
                        ':hover': {
                            background: "#fff",
                        }
                    }}
                    variant={"contained"}
                    color={"primary"}
                    onClick={handleClick}
                >
                    <Typography sx={{
                        color: "#000",
                        fontFamily: "crayond_regular",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "20px",
                        textTransform: 'none'
                    }}>Back to Tree Hugger</Typography>
                </Button>
            </div>

        </div>
    );
}