import React from "react";
import { AddTreeForm } from './addTree';
import { withNavBars } from "./../../HOCs";

class AddTree extends React.Component {
  render() {
    return <AddTreeForm {...this.props} />;
  }
}

export default withNavBars(AddTree);
