import React, { useState } from "react";
import makeStyles from '@mui/styles/makeStyles';
import {
  TopNavBar,
  // SideNavBar
} from '../components';
// import { useApolloClient } from "@apollo/client";
// import { GET_ALL_FORESTS } from '../graphql/queries'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display:'flex',
    flexDirection:'column'
  },
  content: {
    width: '100%',
    // height: 'calc(100% - 64px)',
    flex:1,
    overflow: "auto",
  },
}));


const withNavBars = (Component) => (props) => {
  const classes = useStyles({ props });

  // const client = useApolloClient();

  const [searchValue, setSearchValue] = useState('');

  const onChangeSearch = (value) => {
    setSearchValue(value);
  }

  // useEffect(() => {
  //   getAllForests()
  // }, [])

  // const getAllForests = async () => {
  //   await client.query({
  //     query: GET_ALL_FORESTS,
  //   }).then(res => {
  //     console.log(res)
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // }

  return (
    <div className={classes.root}>
      {/* Your nav bars here */}
      <div>
        <TopNavBar
          searchValue={searchValue}
          onChangeSearch={onChangeSearch}
          options={[]}
        />
      </div>

      {/* {<div className={classes.sideNavbar}>
        <SideNavBar />
      </div>} */}

      {/* Content */}
      <div className={classes.content}>
        <Component searchValue={searchValue} {...props}>{props.children}</Component>
      </div>
    </div>
  );
};

export default withNavBars;
