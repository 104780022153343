import React from "react";

export const ShareIcon = (props) => {
    const width = props?.width ? props?.width : 11.43;
    const height = props?.height ? props?.height : 11.43;
    const color = props?.color ? props?.color : '#17970e';
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 11.43 11.43"
            {...props}
        >
            <path id="icons8-share" d="M14.324,5a2.112,2.112,0,0,0-2.105,2.105,2.009,2.009,0,0,0,.1.5l-3.606,1.8a2.073,2.073,0,0,0-1.608-.8,2.105,2.105,0,0,0,0,4.211,2.073,2.073,0,0,0,1.608-.8l3.606,1.8a2.009,2.009,0,0,0-.1.5,2.105,2.105,0,1,0,2.105-2.105,2.073,2.073,0,0,0-1.608.8l-3.606-1.8a2.008,2.008,0,0,0,.1-.5,2.008,2.008,0,0,0-.1-.5l3.606-1.8a2.073,2.073,0,0,0,1.608.8,2.105,2.105,0,0,0,0-4.211Z" transform="translate(-5 -5)"
                fill={color}
            />
        </svg>
    );
};
