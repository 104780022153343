import { Typography, Button, IconButton, Divider } from "@mui/material";
import { InputField } from '@crayond_dev/ui_input-field';
import { useState, useEffect } from "react";
// import { MobileInput } from '@crayond_dev/ui_mobile-input';
// import { countryCode } from '../../utils/mobileCode';

export default function ContributionDetails(props) {

    const { handleProcessAndPay, HandlePtoPCancel } = props;

    const [state, setState] = useState({
        count: 1,
        name: "",
        mobile: "",
        email: "",
        panNo: ""
    });

    const [charges, setCharges] = useState(0);

    useEffect(() => {
        updateCharges();
    }, [state?.count]);

    const updateCharges = () => {
        const newCharges = state?.count * 299;
        setCharges(newCharges);
    };

    const updateState = (key, value) => {
        setState(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleStateChange = (key, value) => {
        updateState(key, value)
    }

    const incrementCount = () => {
        setState(prevState => ({
            ...prevState,
            count: prevState.count + 1
        }));
    };

    const decrementCount = () => {
        setState(prevState => ({
            ...prevState,
            count: Math.max(0, prevState.count - 1)
        }));
    };




    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: "rgba(11, 148, 68, 0.08)" }}>
            <div style={{
                width: "360px",
                height: "calc(100vh - 64px)",
                background: "#fff",
                overflowY: 'auto'
            }}>


                <div style={{
                    width: "320px",
                    height: "122px",
                    flexShrink: 0,
                    borderRadius: "12px",
                    background: "rgba(11, 148, 68, 0.08)",
                    margin: 'auto',
                    marginTop: '20px',
                    marginBottom: '20px'
                }}>
                    <Typography sx={{
                        color: "#02111A",
                        fontFamily: "crayond_regular",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        letterSpacing: "-0.14px",
                        textAlign: 'center',
                        paddingTop: '16px',
                        paddingBottom: '16px'
                    }}>{"How many trees would you like to plant?"}</Typography>

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={decrementCount}>
                            <img src={"/minusBtn.svg"} />
                        </IconButton>
                        {/* <TextField id="outlined-basic" label="" variant="outlined"

            sx={{
              width: '120px',
              height: "40px",
              //  height: "20px",
              borderRadius: "8px",
              border: "1px solid #fff",
              minHeight: "40px",
              maxHeight: "40px",
              "& input": {
                fontSize: 18,
                padding: 1,
              },
              background: "#FFF"
            }} /> */}
                        <div style={{
                            display: "flex",
                            width: "120px",
                            padding: "11px 16px",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "8px",
                            border: "1px solid #D9DBDD",
                            background: "#FFF"
                        }}>
                            <Typography sx={{
                                color: "#02111A",
                                textAlign: "center",
                                fontFamily: "crayond_regular",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "24px",
                                letterSpacing: "-0.18px"
                            }}>{state?.count}</Typography>
                        </div>
                        <IconButton onClick={incrementCount}>
                            <img src={"/plusBtn.svg"} />
                        </IconButton>
                    </div>

                </div>



                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: "20px", paddingLeft: "20px" }}>
                    <Typography sx={{
                        color: "#4E585E",
                        fontFamily: "crayond_regular",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "20px"
                    }}>{`Charges : ${state?.count} x Rs. 299`}</Typography>
                    <Typography sx={{
                        color: "#02111A",
                        textAlign: "right",
                        fontFamily: "crayond_medium",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "20px"
                    }}>{"Rs." + charges}</Typography>
                </div>

                <Divider sx={{
                    borderStyle: 'dotted',
                    borderWidth: '1px',
                    width: "90%",
                    margin: 'auto',
                    marginTop: '20px'
                }} />

                <div>
                    <Typography sx={{
                        color: "#02111A",
                        fontFamily: "crayond_regular",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "24px",
                        letterSpacing: "-0.14px",
                        padding: '20px'
                    }}>{"Please provide your information"}</Typography>
                    <InputField
                        variant='filled'
                        label='Full Name'
                        placeholder='Enter Full Name'
                        fullWidth
                        onChange={(e) => handleStateChange('name', e.target.value)}
                        value={state?.name}
                        // error={error?.name?.length ? true : false}
                        // errorMessage={error?.name}
                        // isErrorRequired={error?.name?.length ? true : false}
                        required
                        sx={{
                            '& .MuiInputLabel-root': {
                                mt: 0.5,
                            },
                            'pb': 0,
                            width: '320px',
                            margin: 'auto',
                            marginBottom: "-10px",

                        }}
                    />

                    <InputField
                        variant='filled'
                        label='Mobile No'
                        placeholder='Enter Mobile No'
                        fullWidth
                        onChange={(e) => handleStateChange('mobile', e.target.value)}
                        value={state?.mobile}
                        // error={error?.name?.length ? true : false}
                        // errorMessage={error?.name}
                        // isErrorRequired={error?.name?.length ? true : false}
                        required
                        sx={{
                            '& .MuiInputLabel-root': {
                                mt: 0.5,
                            },
                            'pb': 0,
                            width: '320px',
                            margin: 'auto',
                            marginBottom: "-10px",
                            // marginTop: "-10px"
                        }}
                    />

                    {/* <MobileInput
          fullWidth
          id='MobileInput'
          inputStyle={{
            '&:focus': {
              border: '2px solid',
              borderColor: '#D9DBDD',
            },
            '&:hover ': {
              border: '1px solid',
              borderColor: '#D9DBDD',
            },
            'height': '60px !important',
            'padding': '11px 16px',
            'border': '1px solid',
            'borderColor': '#D9DBDD !important',
            '&.MuiInputLabel-root': {
              color: '#4e585e !important',
              mt: 0.2,
            },
          }}
          label='Phone number'
          labelStyle={{
            '& span': {
              color: '#F44F5A',
            },
            'fontSize': '14px',
          }}
          onChange={(e) => {
            console.log(e)
          }}
          placeholder='Enter mobile number'
          sx={{
            width: '100%',
            pb: 2,
          }}
          selectValue={{
            mobile: "",
            mobileCode: "+91",
          }}
          countryCode={countryCode}
          error={false}
          errorMessage={''}
          value={{
            mobile: "",
            mobileCode: "+91",
          }}
          popupIcon={<></>}
          variant='filled'
        /> */}

                    <InputField
                        variant='filled'
                        label='Email'
                        placeholder='Enter Email'
                        fullWidth
                        onChange={(e) => handleStateChange('email', e.target.value)}
                        value={state?.email}
                        // error={error?.name?.length ? true : false}
                        // errorMessage={error?.name}
                        // isErrorRequired={error?.name?.length ? true : false}
                        required
                        sx={{
                            '& .MuiInputLabel-root': {
                                mt: 0.5,
                            },
                            'pb': 0,
                            width: '320px',
                            margin: 'auto',
                            marginBottom: "-10px",

                        }}
                    />
                    <InputField
                        variant='filled'
                        label='PAN Number'
                        placeholder='Enter PAN Number'
                        fullWidth
                        onChange={(e) => handleStateChange('panNo', e.target.value)}
                        value={state?.panNo}
                        // error={error?.name?.length ? true : false}
                        // errorMessage={error?.name}
                        // isErrorRequired={error?.name?.length ? true : false}
                        required
                        sx={{
                            '& .MuiInputLabel-root': {
                                mt: 0.5,
                            },
                            'pb': 0,
                            width: '320px',
                            margin: 'auto',
                            marginBottom: "-10px",

                        }}
                    />

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                        <Button
                            sx={{
                                borderRadius: "8px",
                                background: "#17970E",
                                width: "320px",
                                height: "40px",
                                marginBottom: '12px',
                                marginTop: "24px",
                                ':hover': {
                                    background: "#17970E",
                                }
                            }}
                            variant={"contained"}
                            color={"primary"}
                            onClick={handleProcessAndPay}
                        >
                            <Typography sx={{

                                fontFamily: "crayond_regular",
                                color: "#FFF",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "20px",
                                textTransform: 'none'
                            }}>Proceed & Pay</Typography>
                        </Button>

                        <Button
                            sx={{
                                borderRadius: "8px",
                                border: "1px solid rgba(0, 0, 0, 0.08)",
                                background: "#fff",
                                width: "320px",
                                height: "40px",
                                marginBottom: '24px',
                                boxShadow: 'none',
                                ':hover': {
                                    background: "#fff",
                                }
                            }}
                            variant={"contained"}
                            color={"primary"}
                            onClick={HandlePtoPCancel}
                        >
                            <Typography sx={{

                                fontFamily: "crayond_regular",
                                color: "#000",
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "20px",
                                textTransform: 'none'
                            }}>Cancel</Typography>
                        </Button>
                    </div>

                </div>

            </div>
        </div>
    );
}
