import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from '@mui/material/styles/styled';
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from '@mui/material/IconButton'
import { Box } from "@mui/system";
// import { isVideoType } from "../../utils";

const CustomBox = styled('div', {
    shouldForwardProp: prop => prop !== "height"
})(({ theme, height }) => ({
    position: 'relative',
    outline: 0,
    height,
    width: '100%',
    borderRadius: 6,
    overflow: 'hidden',
    '&:hover': {
        transition: 'all 0.5s',
        '& video': {
            filter: 'brightness(0.7)',
            // transform: 'scale3d(1.15, 1.15, 1.15)',
        },
        '& .hoverDiv': {
            opacity: 1
        }
    }
}));

// const CustomImg = styled('img')({
//     maxWidth: '100%',
//     width: '100%',
//     height: '100%',
//     objectFit: 'cover',
//     transition: 'all 0.5s',
//     borderRadius: 6
// })

const AbsoulteDiv = styled('div')({
    position: 'absolute',
    opacity: 0,
    zIndex: 1,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '100%'
})

const CustomTypography = styled(Typography)({
    flex: 1,
    textAlign: 'center',
    color: '#fff',
    fontSize: 12,
    cursor: 'pointer'
})

const CustomIconButton = styled(IconButton)({
    backgroundColor: '#fff',
    '&:hover': {
        backgroundColor: '#fff',
    }
})

export const Carousel = (props) => {

    const {
        height,
        videos = []
    } = props;

    const sliderRef = useRef(null);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (current) => stopVideo(current)
    };

    const previous = () => {
        sliderRef.current.slickPrev()
    }

    const next = () => {
        sliderRef.current.slickNext()
    }

    const stopVideo = () => {
        let video = document.querySelector('.slick-active video');
        video.pause();
        video.currentTime = 0;
    }

    // const checkVideoType = useCallback((_) => isVideoType(_), [])

    return (
        <Box sx={[
            {
                '& .slick-slider': {
                    overflow: 'hidden'
                }
            }
        ]}>
            <Slider ref={(slider) => (sliderRef.current = slider)} {...settings}>
                {videos?.length && videos?.map((_, i) => (
                    <CustomBox height={height} key={i}>
                        {/* {
                            checkVideoType(_) ? (
                                <video controls height={"164px"} width={"100%"} style={{ objectFit: "cover" }}>
                                    <source src="https://www.w3schools.com/html/mov_bbb.mp4" type="video/mp4" />
                                </video>
                            ) : (
                                <iframe width="100%" height="164"
                                    // src={_}>
                                    src={"https://www.youtube.com/embed/kiEiUlt6sas"}>
                                </iframe>
                            )
                        } */}

                        <iframe
                            width="100%"
                            height="164"
                            src={_}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                        ></iframe>

                        {
                            videos?.length > 1 &&
                            <AbsoulteDiv className="hoverDiv">
                                <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                    sx={{
                                        height: 1,
                                        padding: 2
                                    }}
                                >
                                    <CustomIconButton size="small" onClick={previous}>
                                        <ArrowBackIosNewIcon
                                            htmlColor="#000"
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                        />
                                    </CustomIconButton>
                                    <CustomTypography>View All Assets</CustomTypography>
                                    <CustomIconButton size="small" onClick={next}>
                                        <ArrowForwardIosIcon
                                            htmlColor="#000"
                                            sx={{
                                                fontSize: '12px'
                                            }}
                                        />
                                    </CustomIconButton>
                                </Stack>
                            </AbsoulteDiv>
                        }
                    </CustomBox>
                ))}
            </Slider>
        </Box>
    )

}