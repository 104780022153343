import React from 'react'

const NotepadIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10.25 1.5C9.1017 1.5 8.15285 2.38331 8.02539 3.5H6.25C5.01625 3.5 4 4.51625 4 5.75V19.25C4 20.4838 5.01625 21.5 6.25 21.5H17.75C18.9838 21.5 20 20.4838 20 19.25V5.75C20 4.51625 18.9838 3.5 17.75 3.5H15.9746C15.8471 2.38331 14.8983 1.5 13.75 1.5H10.25ZM10.25 3H13.75C14.1732 3 14.5 3.32675 14.5 3.75C14.5 4.17325 14.1732 4.5 13.75 4.5H10.25C9.82675 4.5 9.5 4.17325 9.5 3.75C9.5 3.32675 9.82675 3 10.25 3ZM6.25 5H8.38477C8.79062 5.60095 9.47736 6 10.25 6H13.75C14.5226 6 15.2094 5.60095 15.6152 5H17.75C18.1732 5 18.5 5.32675 18.5 5.75V19.25C18.5 19.6733 18.1732 20 17.75 20H6.25C5.82675 20 5.5 19.6733 5.5 19.25V5.75C5.5 5.32675 5.82675 5 6.25 5ZM14.3008 8.50488C13.7366 8.50488 13.1732 8.7174 12.7471 9.14355L8.37109 13.5195C8.27331 13.6171 8.20437 13.7398 8.17188 13.874L7.52051 16.5742C7.49032 16.6991 7.49274 16.8297 7.52753 16.9535C7.56233 17.0772 7.62833 17.1899 7.71921 17.2808C7.8101 17.3717 7.92281 17.4377 8.04653 17.4725C8.17026 17.5073 8.30085 17.5097 8.42578 17.4795L11.126 16.8271C11.2601 16.7949 11.3828 16.7263 11.4805 16.6289L15.8555 12.2529H15.8564C16.7084 11.4002 16.7078 9.99587 15.8555 9.14355C15.4293 8.7174 14.865 8.50488 14.3008 8.50488ZM14.3008 9.99512C14.4781 9.99512 14.6556 10.0648 14.7949 10.2041C15.0736 10.4828 15.074 10.914 14.7949 11.1934L10.5693 15.4189L9.26758 15.7324L9.58105 14.4307L13.8076 10.2041C13.947 10.0648 14.1235 9.99512 14.3008 9.99512Z" fill="#0C9152" />
            </svg>
        </>
    )
}

export default NotepadIcon