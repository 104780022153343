import React, {Component} from 'react'
import GoogleMapReact from 'google-map-react'
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default class GoogleMapContainer extends Component {

  setGoogleMapRef (map, maps) {
    this.googleMapRef = map
    this.googleRef = maps
    // let locations = [
    //         {lat: -31.563910, lng: 147.154312},
    //         {lat: -33.718234, lng: 150.363181},
    //         {lat: -33.727111, lng: 150.371124}]
    // let markers = locations && locations.map((location) => {
    //     return new this.googleRef.Marker({position: location})
    // })
    // new MarkerClusterer(map, markers, {
    //   imagePath: 'https://googlemaps.github.io/js-marker-clusterer/images/m',
    //   gridSize: 10,
    //   minimumClusterSize: 2
    // })

    let icon = window.btoa(`<svg xmlns="http://www.w3.org/2000/svg" width="20" height="36" viewBox="0 0 10.683 15.906">
        <g id="Tree_hugger_green_" data-name="Tree hugger (green)" transform="translate(-1356 -912)">
          <path id="Path_11" data-name="Path 11" d="M10.682,7.444c0,4.111-5.352,8.462-5.352,8.462S0,11.555,0,7.444,1.856,0,5.306,0s5.376,3.333,5.376,7.444" transform="translate(1356 912)" fill="#337630"/>
          <path id="Path_12" data-name="Path 12" d="M5.567,9.886s-3.04.243-3.256-2.153c-.139-1.608.759-2.014.759-2.014s.54-2.362,2.211-2.435C7.815,3.174,7.889,5.91,7.889,5.91s2.1,3.673-2.322,3.976" transform="translate(1356 912)" fill="#fff"/>
          <line id="Line_1" data-name="Line 1" y2="1.803" transform="translate(1361.295 921.983)" fill="#fff"/>
          <path id="Path_13" data-name="Path 13" d="M5.3,12.25c-.432,0-.625-.233-.625-.465v-2.3l.4.028A1.012,1.012,0,0,1,5.3,9.484h.23l.395.009v2.292c0,.232-.193.465-.625.465" transform="translate(1356 912)" fill="#337630"/>
          <path id="Path_14" data-name="Path 14" d="M5.045,9.885s.112.008.25.008.25-.006.25-.006v1.9c0,.05-.112.09-.25.09s-.25-.04-.25-.09Zm-.749-.8v2.7a.885.885,0,0,0,1,.84.885.885,0,0,0,1-.84V9.1l-.788.039c-.006,0-.1.005-.212.005s-.19-.005-.2-.006Z" transform="translate(1356 912)" fill="#fff"/>
          <line id="Line_2" data-name="Line 2" y2="3.231" transform="translate(1361.295 918.502)" fill="#fff"/>
          <path id="Path_15" data-name="Path 15" d="M5.3,9.982c-.138,0-.25-.01-.25-.01V6.506c0-.091.112-.165.25-.165s.25.074.25.165v3.47s-.112.006-.25.006" transform="translate(1356 912)" fill="#337630"/>
          <line id="Line_3" data-name="Line 3" x2="1.421" y2="1.765" transform="translate(1359.841 919.167)" fill="#fff"/>
          <path id="Path_16" data-name="Path 16" d="M5.263,9.182a.25.25,0,0,1-.2-.093L3.647,7.324a.25.25,0,1,1,.389-.314L5.458,8.775a.251.251,0,0,1-.2.407" transform="translate(1356 912)" fill="#337630"/>
          <line id="Line_4" data-name="Line 4" x1="0.897" y2="1.162" transform="translate(1361.327 920.148)" fill="#fff"/>
          <path id="Path_17" data-name="Path 17" d="M5.327,9.56a.249.249,0,0,1-.2-.4l.9-1.163a.25.25,0,0,1,.4.306l-.9,1.161a.248.248,0,0,1-.2.1" transform="translate(1356 912)" fill="#337630"/>
          <path id="Path_18" data-name="Path 18" d="M6.977,5.792a.252.252,0,0,1-.249-.223,1.732,1.732,0,0,0-.652-1.1.25.25,0,1,1,.3-.4,2.223,2.223,0,0,1,.853,1.446A.25.25,0,0,1,7,5.791H6.977" transform="translate(1356 912)" fill="#337630"/>
          <rect id="Rectangle_42" data-name="Rectangle 42" width="10.683" height="15.906" transform="translate(1356 912)" fill="none"/>
        </g>
      </svg>
      `);

    const { allTrees } = this.props;

    let markers = allTrees && allTrees.map((location) => {
        return new window.google.maps.Marker({
        position: {
            "lat": Number(location?.forestTreeGeoLat),
            "lng": Number(location?.forestTreeGeoLong)
        },
        icon: {
          url: `data:image/svg+xml;base64,${icon}`,
          // scaledSize: new window.google.maps.Size(35, 75)
        },
        data: location,
      })
    })

    // Add click event listeners to each marker
    markers.forEach((marker) => {
      marker.addListener('click', function() {
        // Handle the marker click event
        console.log(`Marker clicked: ${JSON.stringify(marker.data)}`);
      });
    });

    new MarkerClusterer({map, markers});

  }

  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  }
  render () {
    console.log("this.props.center??{}", JSON.stringify(this.props.center??{}))
    return (
        <GoogleMapReact
          bootstrapURLKeys={{key: 'AIzaSyC460AqqU6PgMO4s5wJvE5GLge9evj4r6o'}}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => this.setGoogleMapRef(map, maps)}
          defaultCenter={this.props.center}
          defaultZoom={11}
          options={{streetViewControl: true}}
        >
          {/* {this.props.forestList.length > 0 && 
              <p>{"Forest"}</p>
          } */}
        </GoogleMapReact>
    )
  }
}
