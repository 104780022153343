import { useState, useEffect } from 'react';

export const Polygon = (props) => {

    const {
        infoWindow = null,
        setInfoWindow = null,
    } = props;

    const [circle, setCircle] = useState(null);

    useEffect(() => {
        if (!circle) {
            setCircle(new window.google.maps.Polygon({
                // paths: triangleCoords,
            }));
        }
        if (!infoWindow) {
            setInfoWindow(new window.google.maps.InfoWindow({
                content: '',
                // pixelOffset: new window.google.maps.Size(100,100),
                // disableAutoPan: true
            }))
        }

        // remove circle from map on unmount
        return () => {
            if (circle) {
                circle.setMap(null);
            }
            if (infoWindow) {
                infoWindow.setMap(null);
            }
        };
        // eslint-disable-next-line
    }, [circle, infoWindow]);

    useEffect(() => {
        if (circle) {
            circle.setOptions(props);
        }
        // eslint-disable-next-line
    }, [props])


    useEffect(() => {

        if (circle) {

            circle.addListener("mouseover", (e) => {
                circle.setOptions(props?.mouseover)
            });

            circle.addListener("mouseout", () => {
                circle.setOptions(props?.mouseout)
            });

            circle.addListener("click", () => {
                props?.circleClick && props?.circleClick()
            });
        }
        // eslint-disable-next-line
    }, [circle]);


    return props?.children??null;
}