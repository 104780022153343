import React from 'react'

const DiaIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13 1.3H2C1.6134 1.3 1.3 1.6134 1.3 2V13C1.3 13.3866 1.6134 13.7 2 13.7H13C13.3866 13.7 13.7 13.3866 13.7 13V2C13.7 1.6134 13.3866 1.3 13 1.3ZM2 0C0.895431 0 0 0.89543 0 2V13C0 14.1046 0.89543 15 2 15H13C14.1046 15 15 14.1046 15 13V2C15 0.895431 14.1046 0 13 0H2Z" fill="#4ABE02" />
                <path d="M11 7.43137C11 8.61438 10.654 9.50654 9.96194 10.1078C9.27682 10.7026 8.31488 11 7.07612 11H5V4H7.29412C8.04844 4 8.70242 4.13072 9.25605 4.39216C9.80969 4.65359 10.2388 5.03922 10.5433 5.54902C10.8478 6.05882 11 6.68627 11 7.43137ZM9.82699 7.46078C9.82699 6.57843 9.60208 5.93137 9.15225 5.51961C8.70242 5.10131 8.05882 4.89216 7.22145 4.89216H6.12111V10.1078H7.02422C8.89273 10.1078 9.82699 9.22549 9.82699 7.46078Z" fill="#4ABE02" />
            </svg>
        </>
    )
}

export default DiaIcon