import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/dist/styles.css";
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Carousel } from '../carousel';
import { Grid } from '@mui/material';
import { ShareIcon } from '../svg';
import copyClipIcon from '../../assets/copy-64.png';
// import { AlertContext } from "../../contexts";
import { FacebookShareButton, EmailIcon, FacebookIcon, EmailShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton, LinkedinIcon, LinkedinShareButton } from 'react-share';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import "yet-another-react-lightbox/styles.css";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CustomTypography = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "color" && prop !== 'fontSize' && prop !== 'fontWeight' && prop !== 'marginBottom' && prop !== 'fontFamily'
})(({ color = "#363A57", fontSize = 12, fontWeight, marginBottom, fontFamily }) => ({
    color,
    fontSize,
    fontWeight,
    marginBottom,
    fontFamily
}));

const CustomTypographyChip = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "color" && prop !== 'fontSize' && prop !== 'fontWeight' && prop !== 'marginBottom' && prop !== 'fontFamily'
})(({ color = "#363A57", fontSize = 12, fontWeight, marginBottom, fontFamily, background, width = "fit-content" }) => ({
    color,
    fontSize,
    fontWeight,
    marginBottom,
    fontFamily,
    background,
    width,
}));

const CustomBox = styled('div', {
    shouldForwardProp: prop => prop !== "padding" && prop !== "backgroundColor" && prop !== "width" && prop !== "height" && prop !== "borderRadius" && prop !== "marginBottom"
})(({ padding, backgroundColor, width = "128px", height = "70px", borderRadius = 6, marginBottom }) => ({
    padding,
    backgroundColor,
    width,
    height,
    borderRadius,
    marginBottom,
    overflow: "hidden"
}))

const CustomImg = styled('img')({
    maxWidth: '100%',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
})

const SponsoredLogo = styled('div')({
    width: 50,
    height: 50,
    overflow: "hidden"
})

const AvatarWrapper = styled('div')({
    height: "auto",
    overflow: "hidden",
    borderRadius: "8px",
    marginBlock: "8px",
    position: "relative"
})

const AvatarName = styled('div')({
    borderRadius: "6px",
    padding: "6px 12px",
    color: "#FFFFFF",
    fontFamily: "crayond_bold",
    fontSize: 12,
    backgroundColor: "#0000005e",
    position: "absolute",
    bottom: 8,
    left: 8,
})

const CoptBtn = styled('div')({
    background: "#80808029",
    padding: "7px",
    borderRadius: "50px",
    width: "34px",
    height: "34px",
    marginLeft: "12px",
    display: "flow",
    top: "-8px",
    position: "relative"
})


const Box = styled('div', {
    shouldForwardProp: prop => prop !== "width"
})(({ theme, width = "100%" }) => ({
    width,
    "@media (max-width:375px)": {
        width: '100%',
    }
}))

const CustomDivider = styled(Divider)({
    marginBlock: '12px'
})

const PopoverCss = styled(Popover)({
    padding: "12px"
})
export const InfoDetails = (props) => {
    // const alertMessage = useContext(AlertContext);

    const [openSnak, setOpenSnak] = React.useState(false);

    const handleCloseSnak = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnak(false);
      };

    const {
        title = "",
        description = "",
        logo = "",
        showVideo = false,
        showImage = false,
        showSponsorName= true, 
        videos = [],
        subTitle = "",
        info = [],
        width,
        onClose = null,
        status = "",
        loading = false,
        sponsoredName = "",
        sponsoredLogo = "",
        avatarUrl = "",
        avatarName = "",
        share = false,
        url = "",
        uniqueId = "",
        images = null,
        geoLocation = {}
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [imagePopUp, setImagePopUp] = React.useState(-1);
    
    const linkToCopy = React.useRef(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    const handleCopyClick = () => {
        // Copy the text inside the text field
        navigator.clipboard.writeText(url);
        setOpenSnak(true);
        handleClose()
    };

    const ImageData = (data) => {
        let images = [];
        data.map(val=>{
            let obj = {
                src: val.url,
            }
            images.push(obj);
        })
        return images;
    }

    return (
        <Box width={width}>
            {
                loading ? (
                    <div style={{ padding: 16 }}>
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            sx={{ mb: 1 }}
                        >
                            <Skeleton variant="text" width={"30%"}></Skeleton>
                            <div style={{ flex: 1 }} />
                            <Skeleton variant="circular" width={20} height={20}></Skeleton>
                        </Stack>
                        {/* <Skeleton variant="rectangular" width={30} height={10}></Skeleton> */}
                        <Skeleton variant="text" width={"100%"}></Skeleton>
                        <Skeleton variant="text" width={"100%"}></Skeleton>
                        {showVideo && <Skeleton sx={{ my: 2, borderRadius: '6px' }} variant="rectangular" width={'100%'} height={164}></Skeleton>}
                        <Skeleton sx={{ mb: 1 }} variant="text" width={"30%"}></Skeleton>
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            gap={"10px"}
                        >
                            {[1, 2].map((_, i) => (
                                <Skeleton key={i} sx={{ borderRadius: '6px' }} variant="rectangular" width={132} height={70}></Skeleton>
                            ))}
                        </Stack>
                    </div>
                ) : (
                    <>
                        <Stack
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            padding={2}
                            paddingBottom={1}
                            position={"sticky"}
                            top={0}
                            sx={{
                                backgroundColor: "#fff",
                                zIndex: 1
                            }}
                        >
                            <Stack
                                direction="row"
                                alignItems={"center"}
                                gap={"10px"}
                            >
                                {title && <CustomTypography fontFamily={"crayond_bold"} fontSize={16} color="#222622" >{title}</CustomTypography>}
                                {logo && <CustomImg>{logo}</CustomImg>}
                            </Stack>
                            <div style={{ cursor: "pointer" }} onClick={onClose} >
                                <CloseIcon fontSize='small' />
                            </div>
                        </Stack>
                        <div style={{ padding: 16, paddingTop: 0, lineHeight: 1 }}>
                            {uniqueId && <CustomTypographyChip 
                                fontFamily={"crayond_bold"} 
                                fontSize={12}
                                background={"rgba(0,0,0,0.2)"}
                                padding={"4px 6px"}
                                borderRadius={"4px"}
                                color={"#222622"}
                                marginBottom={10}
                            >{uniqueId}
                        </CustomTypographyChip>}
                            {status &&
                                <CustomTypography fontFamily={"crayond_bold"} color="#262626" variant='caption' component={"p"}>
                                    <CustomTypography component={"span"} fontFamily={"crayond_medium"} color="#262626">{'Status : '}</CustomTypography>
                                    {status}
                                </CustomTypography>
                            }
                            {(geoLocation?.lat && geoLocation?.long) && <>
                                    <CustomTypography fontFamily={"crayond_bold"} color="#262626" variant='caption' component={"span"}>
                                        <CustomTypography component={"span"} fontFamily={"crayond_medium"} color="#262626">{'Lat-Long : '}</CustomTypography>{geoLocation?.lat}{", "}{geoLocation?.long}
                                    </CustomTypography>
                                </>
                            }
                            {description &&
                                <CustomTypography marginBottom={"8px"} color="#505750" fontFamily={"crayond_regular"}>{description}</CustomTypography>
                            }

                            {(sponsoredName || sponsoredLogo) &&
                                <>
                                   {showSponsorName && <CustomTypography  marginBottom={"8px"} color="#222622" fontFamily={"crayond_bold"} fontSize={14}>{"Sponsored by"}</CustomTypography>}
                                    <Stack
                                        mt={showSponsorName ? '' :'8px'}
                                        direction="row"
                                        alignItems={"center"}
                                        gap={"12px"}
                                        sx={{
                                            border: "1px solid #F1F1F1",
                                            borderRadius: "6px",
                                            padding: "8px 4px"
                                        }}
                                    >
                                        {sponsoredLogo &&
                                            <SponsoredLogo>
                                                <CustomImg src={sponsoredLogo} alt={"sponsorLogo"} />
                                            </SponsoredLogo>
                                        }
                                        <CustomTypography color="#222622" fontFamily={"crayond_medium"} fontSize={12} >{sponsoredName ? sponsoredName : '-'}</CustomTypography>
                                    </Stack>
                                </>
                            }
                            <CustomDivider />
                            {(showVideo) &&
                                <>
                                    <Stack
                                        direction="row"
                                        alignItems={"center"}
                                        justifyContent={"space-between"}
                                        marginBottom={"8px"}
                                    >
                                        <CustomTypography color="#222622" fontFamily={"crayond_bold"} fontSize={14} >{'Videos'}</CustomTypography>
                                        {/* <CustomTypography>{'See all'}</CustomTypography> */}
                                    </Stack>
                                    {
                                        <Carousel videos={videos} height={164} />
                                    }
                                    <CustomDivider />
                                </>
                            }

                            {(showImage && avatarUrl) && 
                                <AvatarWrapper>
                                    {/* {images?.map((img, indexI) => ( */}
                                            <CustomImg
                                                // onClick={()=>setImagePopUp(indexI)}
                                                src={avatarUrl}
                                                alt={"plantedBy"}
                                                sx={{
                                                    // width: "54px",
                                                    // height: "54px",
                                                    padding: "0px 2px 0px 2px",
                                                    cursor: "pointer"
                                                }}
                                            />
                                    {/* ))} */}
                                {/* {avatarName && <AvatarName>{avatarName}</AvatarName>} */}
                                </AvatarWrapper>
                            }

                            {
                                (images?.length > 0) &&
                                <>
                                    {/* <CustomTypography color="#222622" fontFamily={"crayond_bold"} fontSize={14} >{'Planted by'}</CustomTypography> */}
                                    <AvatarWrapper>
                                    {images?.map((img, indexI) => (
                                            <CustomImg
                                                onClick={()=>setImagePopUp(indexI)}
                                                src={img?.url}
                                                alt={"plantedBy"}
                                                sx={{
                                                    width: "54px",
                                                    height: "54px",
                                                    padding: "0px 2px 0px 2px",
                                                    cursor: "pointer"
                                                }}
                                            />
                                    ))}
                                    {/* {avatarName && <AvatarName>{avatarName}</AvatarName>} */}
                                    </AvatarWrapper>
                                    <Lightbox
                                        index={imagePopUp}
                                        slides={ImageData(images)}
                                        open={imagePopUp >= 0}
                                        close={() => setImagePopUp(-1)}
                                    />
                                </>
                            }
                            {subTitle && <CustomTypography marginBottom={"8px"} color="#222622" fontFamily={"crayond_bold"} fontSize={14} >{subTitle}</CustomTypography>}
                            {
                                <div style={{ flex: 1 }}>
                                    <Grid container spacing={1}>
                                        {
                                            info?.length && info?.map((_, i) => (
                                                <Grid item xs={6} key={i}>
                                                    <CustomBox
                                                        key={i}
                                                        backgroundColor="#F1F1F1"
                                                        padding="12px"
                                                        width="100%"
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <CustomTypography sx={{ flex: 1 }} color="#222622" fontFamily={"crayond_bold"} fontSize={14} >{_?.label ?? '-'}</CustomTypography>
                                                        <CustomTypography color="#222622" fontFamily={"crayond_medium"} >
                                                            {_?.value ? _?.value : '-'}
                                                            {_?.unit ? ` ${_?.unit}` : ''}
                                                        </CustomTypography>
                                                    </CustomBox>
                                                </Grid>

                                            ))
                                        }
                                    </Grid>
                                </div>
                            }
                        </div>
                        

                        {
                            share &&
                            <Button
                                onClick={handleClick}
                                variant="contained"
                                fullWidth
                                // onClick={buttonAction}
                                sx={{
                                    backgroundColor: "#CBF6C8",
                                    borderRadius:0,
                                    color: "#17970E",
                                    fontFamily: "crayond_medium",
                                    textTransform: "capitalize",
                                    p: "10px",
                                    boxShadow: "none",
                                    position:"sticky",
                                    bottom:0,
                                    '&:hover': {
                                        backgroundColor: "#CBF6C8",
                                        color: "#17970E",
                                        boxShadow: "none",
                                    }
                                }}
                            >
                                <ShareIcon style={{ marginRight: 8 }} />
                                {'Share'}
                            </Button>
                        }

                        {/* {
                            share &&
                            <Typography
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                                sx={{
                                    textAlign: "center",
                                    backgroundColor: "#CBF6C8",
                                    borderRadius:0,
                                    color: "#17970E",
                                    fontFamily: "crayond_medium",
                                    textTransform: "capitalize",
                                    p: "10px",
                                    boxShadow: "none",
                                    position:"sticky",
                                    bottom:0,
                                    '&:hover': {
                                        backgroundColor: "#CBF6C8",
                                        color: "#17970E",
                                        boxShadow: "none",
                                    }
                                }}
                            >
                                <ShareIcon style={{ marginRight: 8 }} />{'Share'}
                            </Typography>
                        } */}
                    </>
                )
            }

            <PopoverCss
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                styles={{
                    padding: "14px 14px 6px 14px"
                }}

            >
                <div style={{padding: "14px 14px 6px 14px"}}>
                    <LinkedinShareButton style={{paddingRight: "12px"}} url={url} quote={"title"} ><LinkedinIcon round size={34}/></LinkedinShareButton>
                    <EmailShareButton  style={{paddingRight: "12px"}} url={url} quote={"title"} ><EmailIcon round size={34}/></EmailShareButton>
                    <FacebookShareButton style={{paddingRight: "12px"}} url={url} quote={"title"} ><FacebookIcon round size={34}/></FacebookShareButton>
                    <WhatsappShareButton  style={{paddingRight: "12px"}} url={url} quote={"title"} ><WhatsappIcon round size={34}/></WhatsappShareButton>
                    <TwitterShareButton url={url} quote={"title"} ><TwitterIcon round size={34}/></TwitterShareButton>
                    <EmailShareButton onClick={handleCopyClick}>
                        <CoptBtn>
                        <img style={{width: "18px", height: "auto"}} src={copyClipIcon} alt={"logo"} />
                        </CoptBtn>
                    </EmailShareButton>
                </div>

            </PopoverCss>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnak}
                autoHideDuration={1000}
                onClose={handleCloseSnak}
                message={"Share link is copied!"}
                // action={action}
            />

        </Box >
    )
}