import React from 'react'

const OxygenIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                <path d="M2.16667 0C1.73148 0 1.28782 0.159441 0.973633 0.473633C0.659441 0.787825 0.5 1.23148 0.5 1.66667V13.3333C0.5 13.7685 0.659441 14.2122 0.973633 14.5264C1.28782 14.8406 1.73148 15 2.16667 15H13.8333C14.2685 15 14.7122 14.8406 15.0264 14.5264C15.3406 14.2122 15.5 13.7685 15.5 13.3333V1.66667C15.5 1.23148 15.3406 0.787825 15.0264 0.473633C14.7122 0.159441 14.2685 0 13.8333 0H2.16667ZM2.16667 1.66667H13.8333V13.3333H2.16667V1.66667ZM8.00814 4.06413C7.45564 4.06413 6.96432 4.19543 6.53516 4.46126C6.10599 4.7271 5.77406 5.10801 5.53906 5.60384C5.30406 6.09967 5.18424 6.67497 5.18424 7.32747V7.65462C5.18758 8.29129 5.30891 8.85654 5.54557 9.3457C5.78224 9.83487 6.11488 10.2136 6.54655 10.4769C6.97738 10.7419 7.46711 10.874 8.01628 10.874C8.57211 10.874 9.06414 10.7403 9.49414 10.4736C9.92331 10.207 10.2537 9.82598 10.4837 9.33431C10.7146 8.84264 10.8304 8.27131 10.8304 7.61881V7.32259C10.8304 6.67342 10.7122 6.09967 10.4772 5.60384C10.2422 5.10801 9.91112 4.7271 9.48112 4.46126C9.05112 4.19543 8.56064 4.06413 8.00814 4.06413ZM8.00814 5.19206C8.46897 5.19206 8.82342 5.37238 9.07259 5.73405C9.32176 6.09572 9.44531 6.62271 9.44531 7.31771H9.44694V7.64323C9.44361 8.33156 9.31842 8.85633 9.07259 9.21549C8.82676 9.57466 8.47461 9.75423 8.01628 9.75423C7.54961 9.75423 7.19122 9.56684 6.94206 9.19434C6.69289 8.821 6.56934 8.29548 6.56934 7.61881V7.29655C6.57184 6.60738 6.69777 6.08421 6.94694 5.72754C7.19527 5.37087 7.5498 5.19206 8.00814 5.19206Z" fill="#022BBE" />
            </svg>
        </>
    )
}

export default OxygenIcon