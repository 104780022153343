import React from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

const customStyles = {
  control: (base) => ({
    ...base,
    borderRadius: 8,
    zIndex:1,
    // height: 45,
    fontSize: "14px",
    fontFamily:"crayond_medium",
    // minHeight: 45,
    border: "1.5px solid #E2E2E2",
    boxShadow: "none",
    "&:hover": {
      border: "1.5px solid #E2E2E2",
    },
  }),
  placeholder: defaultStyles => {
    return {
      ...defaultStyles,
      fontFamily:"crayond_regular",
      fontSize:14
    };
  },
  menu: defaultStyles => {
    return {
      ...defaultStyles,
      zIndex:101
    };
  },
  option: (defaultStyles,prop) => {
    return {
      ...defaultStyles,
      fontFamily:"crayond_regular",
      fontSize:14,
      color:prop?.isSelected ? "#fff" : "#222622"
    };
  },
};

export const AsyncPaginateComponenet = ({
  isPaginate = false,
  isReadOnly = false,
  value = "",
  placeholder = "",
  options = [],
  loading = false,
  loadOptions = () => null,
  onChange = () => null,
  isMulti = false,
  reduceOptions=undefined,
  debounceTimeout=0,
  key,
}) => {
  
  return (
    <div style={{width:"100%"}}>
      {isPaginate ? (
        <AsyncPaginate
          key={key}
          isClearable
          isSearchable
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          placeholder={placeholder}
          loadOptions={loadOptions}
          onChange={onChange}
          options={options}
          isLoading={loading}
          defaultOptions={options}
          styles={customStyles}
          isDisabled={isReadOnly}
          isMulti={isMulti}
          debounceTimeout={debounceTimeout}
          reduceOptions={reduceOptions}
        />
      )  : (
        <Select
          isClearable={false}
          isSearchable
          components={{
            IndicatorSeparator: () => null,
          }}
          value={value}
          autoComplete="off"
          placeholder={placeholder}
          options={options}
          isLoading={loading}
          onChange={onChange}
          styles={customStyles}
          isMulti={isMulti}
          isDisabled={isReadOnly}
          isSelectAll
        />
      )}
    </div>
  );
};
