import React, { useState } from 'react';
import { PlusIcon, MinusIcon, FullScreenIcon } from '../../components/svg';
import default_map from '../../assets/default_map.png'
import satellite_map from '../../assets/satellite_map.png'
import hybrid_map from '../../assets/hybrid_map.png'
import './index.css';
import { LocalStorageKeys } from '../../utils';
import { Typography } from '@mui/material';

const MAPTYPEOPTIONS = [
    { label: 'Default', value: 'roadmap', url: default_map },
    { label: 'Satellite', value: 'satellite', url: satellite_map },
    { label: 'Hybrid', value: 'hybrid', url: hybrid_map }
]

export const CustomControls = (props) => {

    const [state, setState] = useState({
        mapType: MAPTYPEOPTIONS[0],
        toggle: false
    })

    const onChangeState = (key, value) => {
        setState({
            ...state, [key]: value
        })
    }

    const onChangeMapType = (val) => {
        setState({
            ...state,
            mapType: val,
            toggle: false
        })
        props?.changeMapTypes && props?.changeMapTypes(val.value)
    }

    return (
        <div>
            <div className="fullscreen-control">
                <div className='icon-wrapper' onClick={props?.fullScreenControl}>
                    <FullScreenIcon />
                </div>
            </div>
            <div className="controls">
                {/* <div className='icon-wrapper mb-20' onClick={props?.getCurrentLocation}>
                    <LocationIcon />
                </div> */}
                <div className='zoom-control'>
                    <div className='icon-wrapper mb-10' onClick={props?.zoomIn}>
                        <PlusIcon />
                    </div>
                    <div className='icon-wrapper' onClick={props?.zoomOut}>
                        <MinusIcon />
                    </div>
                </div>
            </div>
            <div className="map-types">
                <div className='mapTypeflex'>
                    <div className='mapTypeCard' style={{ border: '1px solid #22DACE' }} onClick={() => onChangeState('toggle', !state?.toggle)}>
                        <img src={state?.mapType.url} alt={state?.mapType.url + "img"} />
                        <div className='absoluteDiv'>{state?.mapType.label}</div>
                    </div>
                    {state?.toggle && MAPTYPEOPTIONS?.filter(({ label }) => label !== state?.mapType?.label)?.map(_ => (
                        <div className='mapTypeCard' key={_.label} onClick={() => onChangeMapType(_)}>
                            <img src={_.url} alt={_.url + "img"} />
                            <div className='absoluteDiv'>{_.label}</div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="version">
                <Typography variant="caption" sx={{ color: "#fff", fontFamily: "crayond_medium" }}>
                    {`v${localStorage.getItem(LocalStorageKeys.version)}`}
                </Typography>
            </div>
        </div>
    )
}