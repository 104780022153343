import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import { AccordionDetails, AccordionSummary, Autocomplete, Avatar, Backdrop, Box, Button, Grid, IconButton, Popover, Stack, TextField, Typography, styled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import React, { useRef } from 'react';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import OxygenIcon from '../../assets/Oxygen';
import CalendarIcon from '../../assets/calendarIcon';
import CarbanIcon from '../../assets/carbanIcon';
import Celebrate from '../../assets/celebrate_Icon.png';
import CGI from '../../assets/cgi.png';
import CloseIcon from '../../assets/closeIcon';
import CarosalClose from '../../assets/close_carosal.png';
import copyClipIcon from '../../assets/copy-64.png';
import DiaIcon from '../../assets/diaIcon';
import DropdownIcon from '../../assets/dropdownIcon';
import LocationIcon from '../../assets/locationIcon';
import NotepadIcon from '../../assets/notepadIcon';
import PlantIcon from '../../assets/plantIcon';
import ShareIcon from '../../assets/shareIcon';
import TreeAge from '../../assets/treeAge';
import NoImgData from '../../assets/noImgData.jpg';
import Snackbar from '@mui/material/Snackbar';

const BodyContainer = styled(Box)({
    maxWidth: "100%",
    minHeight: "100%",
})

const TopBar = styled(Box)({
    width: "100%",
    height: "90px",
    top: "0px",
    padding: "0 26px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "12px 12px 0px 0px",
    background: "linear-gradient(180deg, #73B523 0%, #0C6424 173.03%)"
})

const TopTitle = styled(Typography)({
    // fontFamily: "Noto Sans",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "24.52px",
    textAlign: "center",
    color: "#FFFFFF",
    margin: 0
})

const IconBtns = styled(IconButton)({
    width: "36px",
    height: "36px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 8px 0px #376F20",
    ':hover': {
        background: "#FFFFFF",
    }
})

const ContentRoot = styled(Box)({
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",

})

const TreeInfoRoot = styled(Box)(({ theme, height }) => ({
    width: "100%",
    height: height,
    padding: "24px 24px 0 24px",
    borderRight: "2px solid #F2F2F2",
    position: "relative",
}));

const GuestInfoRoot = styled(TreeInfoRoot)({
    width: "70%",
    overflow: "auto"
})

const TreeImageBox = styled(Box)({
    width: "140px",
    height: "140px",
    overflow: "hidden",
    borderRadius: "14px",
    position: "absolute",
    top: '-50px',
    boxShadow: '#ffff 0px 0px 0px 4px'
})

const AliveText = styled(Typography)(({ theme, isAlive }) => ({
    color: isAlive ? "#0C9152" : "#3D0600",
    // fontFamily: '"Noto Sans"',
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px"
}))

const AliveDot = styled(Box)(({ theme, isAlive }) => ({
    height: '6px',
    width: "6px",
    borderRadius: "50%",
    backgroundColor: isAlive ? "#0C9152" : "#3D0600"
}));

const AliveRoot = styled(Box)(({ theme, isAlive }) => ({
    display: "flex",
    flexDirection: "row",
    gap: "6px",
    padding: "6px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: isAlive ? "#D6F3E5" : "#FFDAD3"
}));

const TreeName = styled(Typography)({
    color: "#000",
    // fontFamily: '"Noto Sans"',
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "26px"
})

const TreeRowItems = styled(Stack)({
    gap: '12px',
    display: "flex",
    direction: 'row',
    justifyContent: 'start',
    alignItems: "start",
})

const TreeId = styled(AliveRoot)({
    backgroundColor: "#EAEAEA",
    borderRadius: "6px",
    padding: '2px 10px'
})

const TreeLocationText = styled(AliveText)({
    color: "#515151"
})

const ItemTittle = styled(TreeName)({
    fontSize: "16px"
})

const TreeStatusContainer = styled(Box)({
    // height: "72px",
    borderRadius: "12px",
    padding: "12px",
})

const TreeStatusItemText = styled(TreeLocationText)({})

const GiftCard = styled(Box)({
    minHeight: "208px",
    width: "100%",
    backgroundColor: "#ffff",
    borderRadius: "8px",
    boxShadow: " 0px 1px 4px 0px rgba(0, 0, 0, 0.25)"
})

const CardRowContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
})

const TreeTimeline = styled(Timeline)({
    padding: "6px",
    margin: 0,
    '& .MuiTimelineItem-root::before': {
        display: 'none',
    },
})

const TreeTimelineDot = styled(TimelineDot)(({ theme, active }) => ({
    height: "40px",
    width: "40px",
    fontSize: '10px',
    textAlign: "center",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '1px 0px',
    color: active ? "#fff" : '#0C9152',
    border: "1px solid #0C9152",
    backgroundColor: active ? "#0C9152" : "",
    lineHeight: "12px"
}));


const TreeTimelineConnector = styled(TimelineConnector)({
    backgroundColor: '#0C9152',
    width: '1.4px'
})

const TreeTimelineContent = styled(TimelineContent)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '10px'
})

const TimelineTreeImg = styled(Avatar)({
    height: "130px",
    width: "174px",
    borderRadius: "12px"
})

const TreeMobileRootContainer = styled(Box)({
    padding: '0px 16px',
})


const LocationBottomButton = styled(Button)({
    height: '40px',
    width: "100%",
    fontWeight: "500",
    padding: '18px 16px',
    borderRadius: "8px",
    border: "1px solid #0C9152",
    color: "#0C9152",
    ':hover': {
        border: "1px solid #0C9152",
        color: "#0C9152",
    }
})

const ShareBottomButton = styled(LocationBottomButton)({
    color: "#ffff",
    backgroundColor: "#0C9152",
    ':hover': {
        color: "#ffff",
        backgroundColor: "#0C9152",
    }
})

const ButtonContainer = styled(Box)({
    width: '100%',
    padding: '20px',
    bottom: 0,
    left: '0px',
    position: 'absolute',
    gap: '16px',
    display: "flex",
    direction: 'row',
    justifyContent: 'center',
    alignItems: "center",
    backgroundColor: "#ffff"
})

const NextButton = styled(IconBtns)({
    height: '30px',
    width: "30px",
    left: '12px',
    top: '50%',
    position: "absolute",
    transform: 'rotate(90deg)',
    boxShadow: "none"
})
const PrevButton = styled(IconBtns)({
    position: "absolute",
    height: '30px',
    width: "30px",
    right: '12px',
    top: '50%',
    transform: 'rotate(-90deg)',
    boxShadow: "none"
})

const ImageVertical = styled('img')({
    borderRadius: "12px",
    height: "180px",
    width: '148px',
    objectFit: 'cover',
    cursor: "pointer"
})

const ImageHorizondal = styled('img')({
    borderRadius: "12px",
    height: "88px",
    width: '148px',
    objectFit: 'cover',
    cursor: "pointer"
})

const CloseButton = styled(Box)({
    position: "absolute",
    right: "5%",
    top: '8%',
    cursor: "pointer"
})

const TobarButtonContainer = styled(Box)({
    gap: '12px',
    display: "flex",
    direction: 'row',
    justifyContent: 'center',
    alignItems: "center",
})

const CarosalMobContainer = styled(Box)({
    '& .slick-slider': { overflow: 'hidden' }
})

const CarosalWebContainer = styled(Box)({
    '& .slick-slider': { overflow: 'hidden', width: "44vw" }
})

const TitleContainer = styled(Box)({
    padding: { sm: '20px 0px 20px 100px', md: "", }
})

const DateContainer = styled(Box)({
    gap: '8px',
    display: "flex",
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: "center",
})

const CardRowItems = styled(Box)({
    gap: '12px',
    display: "flex",
    direction: 'row',
    justifyContent: 'flex-start',
    alignItems: "center",
})

const WebSliderContainer = styled(Box)({
    height: "80vh",
    width: "100vh",
    position: "relative",
    '& .slick-arrow.slick-next': { right: '-50px' },
    '& .slick-arrow.slick-prev': { left: '-50px' }
})

const PopoverCss = styled(Popover)({
    padding: "12px"
})

const CoptBtn = styled('div')({
    background: "#80808029",
    padding: "7px",
    borderRadius: "50px",
    width: "34px",
    height: "34px",
    marginLeft: "12px",
    display: "flow",
    top: "-8px",
    position: "relative"
})

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid #D4D4D4`,
    padding: "12px",
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const TreeInfo = (props) => {

    const {
        treeName, treeUniqueId, isAlive, treeLat, treeLong, treeHeight, treeDia, treeAge, treeCorban, treeOxygen,
        isGifted, gifterName, gifterUrl, giftDesignation, giftedOrganizationName, giftedOrgUrl, giftedDate,
        url,
        timelineData,
        timelineOrder,
        handleLocationTree = () => { },
        handleCloseTreeInfo = () => { },
        handleOrderTimeline = () => { }
    } = props;

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSnak, setOpenSnak] = React.useState(false);

    const sliderRef = useRef(null);
    const mobile = useMediaQuery('(max-width:600px)');
    const openShare = Boolean(anchorEl);
    const id = openShare ? 'simple-popover' : undefined;

    const handleShare = (event) => {
        setAnchorEl(event.currentTarget);
        console.log(event.currentTarget);
    };

    const handleCloseShare = () => {
        setAnchorEl(null);
    };

    const handleCloseSnak = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnak(false);
    };

    const handleCopyClick = () => {
        // Copy the text inside the text field
        navigator.clipboard.writeText(url);
        setOpenSnak(true);
        handleCloseShare()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const previous = () => {
        sliderRef.current.slickPrev()
    }

    const next = () => {
        sliderRef.current.slickNext()
    }

    const settings = {
        className: "slider variable-width",
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true
    };

    const fadeSettings = {
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false
    };


    function convertDaysToMonthsDays(totalDays) {
        const daysInMonth = 30; // Assuming average month length as 30 days
        const months = Math.floor(totalDays / daysInMonth);
        const days = totalDays % daysInMonth;
        return { months, days };
    }

    const result = convertDaysToMonthsDays(treeAge);

    const Status = [
        {
            label: "Tree height (ft)",
            value: `${treeHeight} feet`,
            icon: <PlantIcon />,
            bgColor: "#EBF5FA"
        },
        {
            label: "Tree Dia (in)",
            value: `${treeDia} inches`,
            icon: <DiaIcon />,
            bgColor: "#F0FAEB"
        },
        {
            label: "Tree age",
            value: result.months ? `${result.months} mon, ${result.days} day(s)` : result.days ? `${result.days} day(s)` : '',
            icon: <TreeAge />,
            bgColor: "#F8FAEB"
        },
        {
            label: "Carbon offest",
            value: `${treeCorban} kgs`,
            icon: <CarbanIcon />,
            bgColor: "#F8EBFA"
        },
        {
            label: "Oxygen created",
            value: `${treeOxygen} kgs`,
            icon: <OxygenIcon />,
            bgColor: "#EBEEFA"
        },
    ]


    const timeLineData = timelineData?.map((item) => ({
        id: item?.id,
        text1: `${item?.height} ft height, ${item?.diameter} in dia`,
        text2: `${moment(item?.createdAt).format('D MMMM, YYYY h:mm A')} | Planted this day`,
        profileUrl: item?.forestPlantTimelineAssetsByTimelineId?.nodes[0]?.url,
        updatedAt: moment(item?.createdAt).format('MMMM D')
    }))

    const carosalData = timelineData?.map((item, i) => {
        const indexId = (i >= 3) ? (i % 3) + 1 : i + 1;
        let data = {
            indexId,
            ...item?.forestPlantTimelineAssetsByTimelineId?.nodes?.find((img) => img?.url)
        };
        return data
    })
    return (
        <BodyContainer>
            <TopBar>
                <Box sx={{ width: "10%" }}></Box>
                {mobile ? "" :
                    <TitleContainer >
                        <TopTitle>{'Thanks for supporting a greener future!'}</TopTitle>
                    </TitleContainer>
                }
                <TobarButtonContainer>
                    {mobile ?
                        <IconBtns onClick={() => handleCloseTreeInfo()}><CloseIcon /></IconBtns> :
                        <>
                            {/* <IconBtns onClick={handleLocationTree}><LocationIcon color={"#0C9152"} /></IconBtns> */}
                            <IconBtns onClick={handleShare}><ShareIcon color={"#0C9152"} /></IconBtns>
                            <IconBtns onClick={() => handleCloseTreeInfo()}><CloseIcon /></IconBtns>
                        </>
                    }
                </TobarButtonContainer>
            </TopBar>

            <ContentRoot>
                <TreeInfoRoot height={!mobile && '78vh'}>
                    <TreeImageBox>
                        <Avatar variant='square' sx={{ height: "110px", width: "100%" }} src={carosalData && carosalData[0]?.url} />
                        <AliveRoot isAlive={isAlive} >
                            <AliveDot isAlive={isAlive} />
                            <AliveText isAlive={isAlive}>{isAlive ? 'Alive' : 'Dead'}</AliveText>
                        </AliveRoot>
                    </TreeImageBox>
                    <Stack direction={'row'} columnGap={'150px'} alignItems={"start"}>
                        <Box></Box>
                        <Box>
                            <Stack gap={'12px'} direction={mobile ? "column" : 'row'} justifyContent={'start'} alignItems={"start"}>
                                <TreeName>{treeName}</TreeName>
                                {mobile && treeUniqueId ? <TreeLocationText>{treeUniqueId}</TreeLocationText> : treeUniqueId && <TreeId>{treeUniqueId}</TreeId>}

                            </Stack>
                            {!mobile &&
                                <Stack gap={'12px'} direction={'row'} justifyContent={'start'} alignItems={"start"} mt={'12px'}>
                                    <LocationIcon color={"#515151"} />
                                    <TreeLocationText>{`${treeLat?.slice(0, 10)}, ${treeLong?.slice(0, 10)}`}</TreeLocationText>
                                </Stack>}
                        </Box>
                    </Stack>
                    {mobile &&
                        <TreeRowItems mt={'18px'}>
                            <LocationIcon color={"#515151"} />
                            <TreeLocationText>{`${treeLat?.slice(0, 10)}, ${treeLong?.slice(0, 10)}`}</TreeLocationText>
                        </TreeRowItems>
                    }
                    {!mobile &&
                        <Stack mt={'24px'}>
                            <ItemTittle mb={'16px'}>Status</ItemTittle>
                            <Grid container spacing={{ xs: '12px' }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {
                                    Status?.map((items, i) => (
                                        <Grid item xs={2} sm={4} md={4} key={i}>
                                            <TreeStatusContainer bgcolor={items?.bgColor}>
                                                <ContentRoot justifyContent={'start'}>
                                                    <TreeStatusItemText>{items?.label}</TreeStatusItemText>
                                                    <Box>{items?.icon}</Box>
                                                </ContentRoot>
                                                <ItemTittle color={'#02111A'}>{items?.value}</ItemTittle>
                                            </TreeStatusContainer>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Stack>}
                    {!mobile &&
                        <Stack mt={'24px'} position={'relative'} >
                            <ItemTittle>Gallery</ItemTittle>
                            {
                                carosalData?.length > 0 ?
                                    <>
                                        <CarosalWebContainer>
                                            <Grid container spacing={{ xs: 0 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                                <Slider ref={(slider) => (sliderRef.current = slider)} {...settings} >
                                                    {carosalData?.map((item, i) => (
                                                        <Grid item xs={2} sm={4} md={4} key={item.id}>
                                                            {item.indexId === 1 ? (
                                                                <Stack m={0.5}>
                                                                    <ImageVertical src={item.url} alt={item.title} onClick={handleOpen} />
                                                                </Stack>
                                                            ) : (
                                                                <Stack gap={'6px'} m={0.5} direction={'column'}>
                                                                    {carosalData.filter((innerItem) => innerItem.indexId === item.indexId).map((innerItem, innerIndex, { length }) => (
                                                                        length - 1 === innerIndex ? <></> : <ImageHorizondal key={innerItem.id} src={innerItem.url} alt={innerItem.title} onClick={handleOpen} />
                                                                    ))}
                                                                </Stack>
                                                            )}
                                                        </Grid>
                                                    ))}
                                                </Slider>
                                            </Grid>
                                        </CarosalWebContainer>
                                        <NextButton onClick={next}><DropdownIcon /></NextButton>
                                        <PrevButton onClick={previous}><DropdownIcon /></PrevButton>
                                    </>
                                    :
                                    <img src={NoImgData} alt={'no data'} style={{ height: '150px', width: '100%', borderRadius: "8px", objectFit: "contain" }} />
                            }

                            <Backdrop
                                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: 'rgba(0,0,0,0.7)' }}
                                open={open}
                            >
                                <CloseButton onClick={() => handleClose()}><img src={CarosalClose} alt='img' /></CloseButton>
                                <WebSliderContainer>
                                    <Slider {...fadeSettings}>
                                        {carosalData?.map((item, i) => (
                                            <div>
                                                <img src={item.url} alt={'kj'} style={{ height: '550px', width: '100%', borderRadius: "8px", objectFit: "cover" }} />
                                            </div>
                                        ))}
                                    </Slider>
                                </WebSliderContainer>
                            </Backdrop>
                        </Stack>}
                </TreeInfoRoot>

                {!mobile &&
                    <GuestInfoRoot>
                        {
                            Boolean(isGifted) &&
                            <GiftCard>
                                <CardRowContainer borderBottom={'2px dashed #D4D4D4'} padding={'20px'}>
                                    <ItemTittle>{'An initiative by'}</ItemTittle>
                                    <img src={CGI} alt={'cgi'} />
                                </CardRowContainer>

                                <CardRowContainer padding={'16px 20px'}>
                                    <Box>
                                        <CardRowItems >
                                            <img src={Celebrate} alt={'cgi'} style={{ height: "25px", width: "25px" }} />
                                            <TreeLocationText>Gifted to</TreeLocationText>
                                        </CardRowItems>
                                        <CardRowItems mt={'8px'}>
                                            <Avatar src={gifterUrl} variant='rounded' sizes={'34px'} />
                                            <Stack>
                                                <ItemTittle>{gifterName}</ItemTittle>
                                                <TreeLocationText>{`${giftDesignation}, ${giftedOrganizationName}`}</TreeLocationText>
                                            </Stack>
                                        </CardRowItems>

                                    </Box>
                                    <Box>
                                        {giftedOrgUrl && <img src={giftedOrgUrl} alt={'org img'} height={'40px'} width={'40px'} />}
                                    </Box>
                                </CardRowContainer>

                                {giftedDate && <CardRowContainer padding={'0 20px 20px'}>
                                    <DateContainer>
                                        <CalendarIcon />
                                        <TreeLocationText>{`on ${moment(giftedDate).format('D MMMM, YYYY')}`}</TreeLocationText>
                                    </DateContainer>
                                    <IconButton><NotepadIcon /> </IconButton>
                                </CardRowContainer>}
                            </GiftCard>
                        }

                        <CardRowContainer mt={'18px'}>
                            <ItemTittle>Timeline</ItemTittle>
                            <Autocomplete
                                value={timelineOrder}
                                defaultValue={{ label: "Oldest to Newest", value: 'CREATED_AT_ASC' }}
                                options={[{ label: "Newest to oldest", value: 'CREATED_AT_DESC' }, { label: "Oldest to Newest", value: 'CREATED_AT_ASC' }]}
                                sx={{
                                    width: 174,
                                    border: "none",
                                    "& .MuiOutlinedInput-root": {
                                        borderRadius: "0",
                                        padding: "0",
                                    },
                                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                        border: "1px solid #eee",
                                    },
                                    "&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input": {
                                        width: 'max-content',
                                    },
                                    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot': {
                                        paddingRight: 0,
                                    },
                                }}
                                renderInput={(params) => <TextField {...params} label="" />}
                                popupIcon={<DropdownIcon />}
                                clearIcon={false}
                                onChange={handleOrderTimeline}
                            />
                        </CardRowContainer>
                        <Stack height={{ sm: "30vh", md: '34vh' }} overflow={'scroll'}>
                            <TreeTimeline position={'right'}>
                                {timeLineData?.map((card, i, { length }) => (
                                    <>
                                        <TimelineItem key={card?.id}>
                                            <TimelineSeparator>
                                                <TreeTimelineDot active={Boolean(i === 0)} variant={i === 0 ? "filled" : 'outlined'}>{card?.updatedAt}</TreeTimelineDot>
                                                {i !== length + 1 && <TreeTimelineConnector />}
                                            </TimelineSeparator>
                                            <TreeTimelineContent >
                                                <Stack direction={'column'}>
                                                    <ItemTittle>{card?.text1}</ItemTittle>
                                                    <TreeStatusItemText mb={'12px'}>{card?.text2}</TreeStatusItemText>
                                                    <TimelineTreeImg src={card?.profileUrl} variant='rounded' />
                                                </Stack>
                                            </TreeTimelineContent>
                                        </TimelineItem>
                                    </>))}
                            </TreeTimeline>
                        </Stack>

                    </GuestInfoRoot>}
            </ContentRoot>
            {
                mobile &&
                <Stack sx={{ height: "54vh", overflow: "auto", mt: 1 }}>
                    <TreeMobileRootContainer>
                        {
                            Boolean(isGifted) ?
                                <GiftCard>
                                    <CardRowContainer borderBottom={'2px dashed #D4D4D4'} padding={'16px'}>
                                        <ItemTittle>{'An initiative by'}</ItemTittle>
                                        <img src={CGI} alt={'cgi'} />
                                    </CardRowContainer>

                                    <CardRowContainer padding={'16px'}>
                                        <Box>
                                            <CardRowItems >
                                                <img src={Celebrate} alt={'cgi'} style={{ height: "25px", width: "25px" }} />
                                                <TreeLocationText>Gifted to</TreeLocationText>
                                            </CardRowItems>
                                            <CardRowItems mt={'8px'}>
                                                <Avatar src={gifterUrl} variant='rounded' sizes={'34px'} />
                                                <Stack>
                                                    <ItemTittle>{gifterName}</ItemTittle>
                                                    <TreeLocationText>{`${giftDesignation}, ${giftedOrganizationName}`}</TreeLocationText>
                                                </Stack>
                                            </CardRowItems>
                                        </Box>
                                        <Box>
                                            {giftedOrgUrl && <img src={giftedOrgUrl} alt={'cgi'} height={'40px'} width={'40px'} />}
                                        </Box>
                                    </CardRowContainer>

                                    {giftedDate && <CardRowContainer padding={'0 16px 16px'}>
                                        <DateContainer>
                                            <CalendarIcon />
                                            <TreeLocationText>{`on ${moment(giftedDate).format('D MMMM, YYYY')}`}</TreeLocationText>
                                        </DateContainer>
                                        <IconButton><NotepadIcon /> </IconButton>
                                    </CardRowContainer>}
                                </GiftCard> : <Box></Box>
                        }
                    </TreeMobileRootContainer>

                    <Stack mt={'20px'} pb={6.5}>
                        <Accordion>
                            <AccordionSummary expandIcon={<DropdownIcon />}>
                                <ItemTittle>Status</ItemTittle>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={{ xs: '16px' }} columns={{ xs: 2, sm: 4 }}>
                                    {
                                        Status?.map((items, i) => (
                                            <Grid item xs={1} sm={2} key={i}>
                                                <TreeStatusContainer bgcolor={items?.bgColor}>
                                                    <ContentRoot justifyContent={'start'}>
                                                        <TreeStatusItemText>{items?.label}</TreeStatusItemText>
                                                        <Box>{items?.icon}</Box>
                                                    </ContentRoot>
                                                    <ItemTittle color={'#02111A'}>{items?.value}</ItemTittle>
                                                </TreeStatusContainer>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion >
                            <AccordionSummary expandIcon={<DropdownIcon />}>
                                <ItemTittle>Timeline</ItemTittle>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack>
                                    <TreeTimeline position={'right'}>
                                        {timeLineData?.map((card, i, { length }) => (
                                            <>
                                                <TimelineItem key={card?.id}>
                                                    <TimelineSeparator>
                                                        <TreeTimelineDot active={Boolean(i === 0)} variant={i === 0 ? "filled" : 'outlined'}>{card?.updatedAt}</TreeTimelineDot>
                                                        {i !== length + 1 && <TreeTimelineConnector />}
                                                    </TimelineSeparator>
                                                    <TreeTimelineContent >
                                                        <Stack direction={'column'}>
                                                            <ItemTittle>{card?.text1}</ItemTittle>
                                                            <TreeStatusItemText mb={'12px'}>{card?.text2}</TreeStatusItemText>
                                                            <TimelineTreeImg src={card?.profileUrl} variant='rounded' />
                                                        </Stack>
                                                    </TreeTimelineContent>
                                                </TimelineItem>
                                            </>))}
                                    </TreeTimeline>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion >
                            <AccordionSummary expandIcon={<DropdownIcon />}>
                                <ItemTittle>Gallery</ItemTittle>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack position={'relative'}>
                                    {
                                        carosalData?.length > 0 ?
                                            <>
                                                <CarosalMobContainer>
                                                    <Grid container spacing={{ xs: 0 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                                        <Slider ref={(slider) => (sliderRef.current = slider)} {...settings} >
                                                            {carosalData?.map((item, i) => (
                                                                <Grid item xs={2} sm={4} md={4} key={item.id}>
                                                                    {item.indexId === 1 ? (
                                                                        <Stack m={0.5}>
                                                                            <ImageVertical src={item.url} alt={item.title} onClick={handleOpen} />
                                                                        </Stack>
                                                                    ) : (
                                                                        <Stack gap={'6px'} m={0.5} direction={'column'}>
                                                                            {carosalData.filter((innerItem) => innerItem.indexId === item.indexId).map((innerItem, innerIndex, { length }) => (
                                                                                length - 1 === innerIndex ? <></> : <ImageHorizondal key={innerItem.id} src={innerItem.url} alt={innerItem.title} onClick={handleOpen} />
                                                                            ))}
                                                                        </Stack>
                                                                    )}
                                                                </Grid>
                                                            ))}
                                                        </Slider>
                                                    </Grid>
                                                </CarosalMobContainer>
                                                <NextButton onClick={next}><DropdownIcon /></NextButton>
                                                <PrevButton onClick={previous}><DropdownIcon /></PrevButton>
                                            </> :
                                            <img src={NoImgData} alt={'no data'} style={{ height: '150px', width: '100%', borderRadius: "8px", objectFit: "contain" }} />
                                    }
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>

                    <ButtonContainer>
                        {/* <LocationBottomButton startIcon={<LocationIcon color={"#0C9152"} />} variant='outlined'>{'Location'}</LocationBottomButton> */}
                        <ShareBottomButton onClick={(e) => handleShare(e)} startIcon={<ShareIcon color={"#ffff"} />} variant='contained'>{'Share'}</ShareBottomButton>
                    </ButtonContainer>
                </Stack>
            }


            <PopoverCss
                id={id}
                open={openShare}
                anchorEl={anchorEl}
                onClose={handleCloseShare}
                anchorOrigin={{
                    vertical: mobile ? 'top' : 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: mobile ? 'bottom' : 'top',
                    horizontal: mobile ? "center" : 'right',
                }}
                sx={{ mt: 1 }}
            >
                <div style={{ padding: "14px 14px 6px 14px" }}>
                    <LinkedinShareButton style={{ paddingRight: "12px" }} url={url} quote={"title"} ><LinkedinIcon round size={34} /></LinkedinShareButton>
                    <EmailShareButton style={{ paddingRight: "12px" }} url={url} quote={"title"} ><EmailIcon round size={34} /></EmailShareButton>
                    <FacebookShareButton style={{ paddingRight: "12px" }} url={url} quote={"title"} ><FacebookIcon round size={34} /></FacebookShareButton>
                    <WhatsappShareButton style={{ paddingRight: "12px" }} url={url} quote={"title"} ><WhatsappIcon round size={34} /></WhatsappShareButton>
                    <TwitterShareButton url={url} quote={"title"} ><TwitterIcon round size={34} /></TwitterShareButton>
                    <EmailShareButton onClick={handleCopyClick}>
                        <CoptBtn>
                            <img style={{ width: "18px", height: "auto" }} src={copyClipIcon} alt={"logo"} />
                        </CoptBtn>
                    </EmailShareButton>
                </div>
            </PopoverCss>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={openSnak}
                autoHideDuration={1000}
                onClose={handleCloseSnak}
                message={"Share link is copied!"}
            />

        </BodyContainer>
    )
}

export default TreeInfo