import React from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { MapComponent } from "./mapComponent";
import { Marker } from "./marker";
import { HoverDetails } from "./hoverDetails";
import { Polygon } from "./polygon";

const areaLatLong = {
  0: [
    {
      forestName: "Kolathoor Hero Forest",
      lat: 12.788509,
      lng: 79.977403,
    },
    {
      lat: 12.785365,
      lng: 79.975993,
    },
    {
      lat: 12.784517,
      lng: 79.976765,
    },
    {
      lat: 12.78671,
      lng: 79.98508,
    },
    {
      lat: 12.788515,
      lng: 79.984038,
    },
  ],
  1: [
    {
      forestName: "Hero Forest",
      lat: 12.728027,
      lng: 80.007713,
    },
    {
      lat: 12.727408,
      lng: 80.007185,
    },
    {
      lat: 12.728027,
      lng: 80.006303,
    },
    {
      lat: 12.727156,
      lng: 80.005578,
    },
    {
      lat: 12.725853,
      lng: 80.008644,
    },
    {
      lat: 12.727033,
      lng: 80.009044,
    },
  ],
};

const extraTrees = [
  {
    lat: 12.726818,
    lng: 80.006161,
  },
  {
    lat: 12.727318,
    lng: 80.006512,
  },
  {
    lat: 12.727899,
    lng: 80.006327,
  },
];

const checkLatLong = [
  {
    lat: 12.950109777557856,
    lng: 80.25670814249283,
  },
  {
    lat: 12.950127241313131,
    lng: 80.25672168371791,
  },
];

export const RenderMap = ({
  center,
  initialZoom,
  minZoom,
  onIdle,
  onClickMap,
  infoWindow,
  mapStyles,
  map,
  setMap,
  allTrees,
  forestsAndTreesList,
  renderStatus,
  selectedNode,
  setInfoWindow,
  circleClick,
  markerIcon,
  markerClick,
  areaClick,
  setAllTrees,
  maxZoom,
  ref,
}) => {
  return (
    <Wrapper
      apiKey={"AIzaSyC460AqqU6PgMO4s5wJvE5GLge9evj4r6o"}
      render={renderStatus}
    >
      {/* <Wrapper apiKey={config.googleMapKey} render={renderStatus}> */}
      <MapComponent
        center={center}
        zoom={initialZoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        onIdle={onIdle}
        onClick={onClickMap}
        // zoom_changed={zoom_changed}
        disableDefaultUI={true}
        keyboardShortcuts={false}
        infoWindow={infoWindow}
        styles={[...mapStyles]}
        map={map}
        setMap={setMap}
        markerList={allTrees?.list}
        markerClick={markerClick}
        polygon={true}
        setAllTrees={setAllTrees}
        clusterZoom={allTrees?.clusterZoom}
      >
        {/* Area Polygon */}
        {forestsAndTreesList?.data?.length &&
          forestsAndTreesList?.data?.map((_, index) => (
            <Polygon
              key={`circle-${index}`}
              nodeId={_?.forestName}
              options={{
                strokeColor:
                  selectedNode?.circle === _?.nodeId ? "#da9328" : "#337630",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor:
                  selectedNode?.circle === _?.nodeId ? "#da9328" : "#337630",
                // fillColor: selectedNode?.circle === _?.nodeId ? "red" : "blue",
                fillOpacity: 0.2,
                center: {
                  lat: Number(_?.forestGeoLat),
                  lng: Number(_?.forestGeoLong),
                },
                radius: Number(_?.forestGeoRadius) / 8,
                paths: _?.forestBoundary ? JSON.parse(_?.forestBoundary) : [],
                // paths: areaLatLong[index]
              }}
              mouseover={{
                // strokeColor: "#22DACE",
                strokeOpacity: 1,
                strokeWeight: 4,
              }}
              mouseout={{
                // strokeColor: selectedNode?.circle === _?.nodeId ? "yellow" : "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
              }}
              hoverComponent={<HoverDetails />}
              infoWindow={infoWindow}
              setInfoWindow={setInfoWindow}
              circleClick={() => areaClick(_, _?.id)}
              map={map}
            >
              {/* Forest Icon */}
              <Marker
                key={`marker-${index}`}
                position={{
                  lat: Number(_?.forestGeoLat),
                  lng: Number(_?.forestGeoLong),
                }}
                zIndex={1000}
                // icon={selectedNode?.circle === _?.nodeId ? markerIcon(ForestIcon) : markerIcon(ForestIcon)}
                url={
                  _?.forestSponsorsByForestId?.nodes?.[0]?.sponsorBySponsorId
                    ?.sponsorForestLogo ?? ""
                }
                markerClick={() => circleClick(_, _?.id)}
                infoWindow={infoWindow}
                setInfoWindow={setInfoWindow}
                map={map}
              />
            </Polygon>
          ))}
        {/* {
                    forestsAndTreesList?.data?.length && forestsAndTreesList?.data?.map((_, index) => (
                        <Circle
                            key={`circle-${index}`}
                            nodeId={_?.forestName}
                            options={{
                                strokeColor: selectedNode?.circle === _?.nodeId ? "#da9328" : "#337630",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: selectedNode?.circle === _?.nodeId ? "#da9328" : "#337630",
                                // fillColor: selectedNode?.circle === _?.nodeId ? "red" : "blue",
                                fillOpacity: 0.2,
                                center: {
                                    "lat": Number(_?.forestGeoLat),
                                    "lng": Number(_?.forestGeoLong)
                                },
                                radius: Number(_?.forestGeoRadius) / 8,
                            }}
                            mouseover={{
                                // strokeColor: "#22DACE",
                                strokeOpacity: 1,
                                strokeWeight: 4,
                            }}
                            mouseout={{
                                // strokeColor: selectedNode?.circle === _?.nodeId ? "yellow" : "#FF0000",
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                            }}
                            hoverComponent={
                                <HoverDetails />
                            }
                            infoWindow={infoWindow}
                            setInfoWindow={setInfoWindow}
                            circleClick={() => circleClick(_?.nodeId, _?.id)}
                        >
                            <Marker
                                key={`marker-${index}`}
                                position={{
                                    "lat": Number(_?.forestGeoLat),
                                    "lng": Number(_?.forestGeoLong)
                                }}
                                icon={selectedNode?.circle === _?.nodeId ? markerIcon(ForestIcon) : markerIcon(ForestIcon)}
                                // icon={<img src={ForestIcon} />}
                                markerClick={() => circleClick(_?.nodeId)}
                                infoWindow={infoWindow}
                                setInfoWindow={setInfoWindow}
                                map={map}
                            />
                            {
                                (_?.forestTreesByForestId?.nodes?.length) && _?.forestTreesByForestId?.nodes?.map((tree, treeIndex) => (
                                    <Marker
                                        key={`marker-${treeIndex}`}
                                        position={{
                                            "lat": Number(tree?.forestTreeGeoLat),
                                            "lng": Number(tree?.forestTreeGeoLong)
                                        }}
                                        icon={selectedNode?.marker === tree?.nodeId ? markerIcon(TreeHuggerOrange) : markerIcon(TreeHuggerGreen)}
                                        markerClick={() => markerClick(tree?.nodeId, _?.nodeId)}
                                        infoWindow={infoWindow}
                                        setInfoWindow={setInfoWindow}
                                        map={map}
                                    />
                                )
                                )
                            }
                        </Circle>

                    ))
                } */}
        {/* {allTrees?.list?.length && allTrees?.list?.map((trees, indexTree) => (
                        <Marker
                            key={`marker-${indexTree}`}
                            position={{
                                "lat": Number(trees?.forestTreeGeoLat),
                                "lng": Number(trees?.forestTreeGeoLong)
                            }}
                            icon={selectedNode.marker === trees?.nodeId ? markerIcon(TreeHuggerOrange) : markerIcon(TreeHuggerGreen)}
                            markerClick={() => markerClick(trees?.nodeId)}
                            infoWindow={infoWindow}
                            setInfoWindow={setInfoWindow}
                            map={map}
                        />
                ))

                } */}
        {/* {forestDetails?.length && forestDetails?.map((_, index) => (
                    <Overlay
                        key={`overlay-${index}`}
                        bounds={{
                            "lat": Number(_?.forestGeoLat),
                            "lng": Number(_?.forestGeoLong)
                        }}
                        radius={_?.forestGeoRadius * 1000}
                        bearing={180}
                        component={<HoverDetails />}
                    />
                ))
                } */}

        {/* {checkLatLong.map(v => (
                            <Marker
                                key={`marker-twst`}
                                position={{
                                    "lat": Number(v?.lat),
                                    "lng": Number(v?.lng)
                                }}
                                zIndex={1000}
                                // icon={selectedNode?.circle === _?.nodeId ? markerIcon(ForestIcon) : markerIcon(ForestIcon)}
                                // url={_?.forestSponsorsByForestId?.nodes?.[0]?.sponsorBySponsorId?.sponsorForestLogo??""}
                                // markerClick={() => circleClick(_, _?.id)}
                                infoWindow={infoWindow}
                                setInfoWindow={setInfoWindow}
                                map={map}
                            />
                        ))} */}
      </MapComponent>
    </Wrapper>
  );
};
